import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { ModalController, AlertController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

/**
 * ID: bh-input-text-editor
 * Name: BH Text Editor
 * Description: A modal-based text editor that makes editing large amounts of text easier. Displays text in full-screen modal pop-up.
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-06-23 - v1: Initial dev
 * 2022-06-17 - v2: Fixed height, auto-focus, etc.
 */
@Component({
  selector: 'app-bh-input-text-editor',
  templateUrl: './bh-input-text-editor.page.html',
  styleUrls: ['./bh-input-text-editor.page.scss'],
})
export class BhInputTextEditorPage implements OnInit {
  @Input() formControlName;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() value = '';
  @Input() readonly = '';
  @Input() maxlength = '';
  newValue = '';
  isSaved = false;

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private analytics: BhAnalyticsService
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.analytics.screenView('bh-input-text-editor');
    this.newValue = this.value;
    setTimeout(() => {
      this.focusTextArea();
    }, 500);
  }

  focusTextArea() {
    const textarea = document.getElementById('texteditor-ion-textarea-' + this.formControlName).getElementsByTagName('textarea')[0];
    if (this.newValue) {
      const len = this.newValue.length;
      if (len > 0) {
        textarea.setSelectionRange(len, len, 'forward');
      }
    } else {
      textarea.focus();
    }
  }

  done() {
    this.analytics.clickEvent('bh-input-text-editor: done', '');
    this.isSaved = true;
    this.dismiss();
  }

  async cancel() {
    this.analytics.clickEvent('bh-input-text-editor: cancel', '');
    if (this.value !== this.newValue) {
      const alert = await this.alertCtrl.create({
        header: 'Leave without saving?',
        message: 'You are about to leave this page without saving your work.',
        cssClass: 'wide-alert',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Leave without saving',
            handler: (val) => {
              this.dismiss();
            }
          },
          {
            text: 'Save and leave',
            handler: (val) => {
              this.done();
            }
          },
          {
            text: 'Continue editing',
            handler: (val) => {
              // console.log('Stayed');
            }
          },
        ]
      });
      await alert.present();

    } else {
      this.dismiss();
    }
  }

  dismiss() {
    this.modalCtrl.dismiss({
      done: this.isSaved,
      value: this.isSaved ? this.newValue : this.value
    });
  }

}
