<div class="content-rater-container">
  <h3 class="cr-question">Was this content helpful?</h3>
  <div class="cr-options">
    <a
      href="javascript:void(0)"
      [ngClass]="{ 'liked-selected': isHelpful === true }"
      (click)="rate(true)"
    >
      <ion-icon name="thumbs-up-outline" *ngIf="!isHelpful"></ion-icon>
      <ion-icon name="thumbs-up" *ngIf="isHelpful"></ion-icon>
      Yes
    </a>
    <a
      href="javascript:void(0)"
      [ngClass]="{ 'disliked-selected': isHelpful === false }"
      (click)="rate(false)"
    >
      <ion-icon
        name="thumbs-down-outline"
        *ngIf="isHelpful !== false"
      ></ion-icon>
      <ion-icon name="thumbs-down" *ngIf="isHelpful === false"></ion-icon>
      No
    </a>
  </div>
  <div class="cr-feedback-container" *ngIf="isHelpful !== null">
    <h3 class="cr-question" *ngIf="isHelpful && !readOnly">
      Tell us what you liked about this content?
    </h3>
    <h3 class="cr-question" *ngIf="!isHelpful && !readOnly">
      Tell us what you didn't like about this content?
    </h3>
    <textarea
      class="cr-textbox"
      placeholder="Write your feedback..."
      [(ngModel)]="comment"
      *ngIf="!readOnly"
    >
    </textarea>
    <div class="readonly-feedback" *ngIf="readOnly && userActivity?.feedbackComment">
      <div class="feedback-timestamp">
        Your wrote on {{ userActivity?.feedbackDateTime | formatTimestamp }}:
      </div>
      <div class="feedback-text">
        "{{ userActivity?.feedbackComment }}"
      </div>
    </div>
    <div class="cr-options">
      <a
        href="javascript:void(0)"
        *ngIf="!readOnly"
        [ngClass]="{
          disabled: !comment
        }"
        (click)="submitFeedback()"
      >
        <ion-icon name="checkmark-outline"></ion-icon>
        Submit feedback
      </a>
      <a href="javascript:void(0)" *ngIf="readOnly" (click)="editFeedback()">
        <ion-icon name="pencil-outline"></ion-icon>
        Edit feedback
      </a>
    </div>
  </div>
</div>
