import { UserDevice } from '../../models/_core/user-device';
import { UserDeviceService } from './../_core/user-device/user-device.service';
import { Platform, NavController } from '@ionic/angular';
import { AuthService } from './../_core/auth/auth.service';
import { NavPage } from './../../models/_core/nav-page';
import { Injectable } from '@angular/core';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { User } from 'src/app/models/user';
import { ContainerService } from '../container/container.service';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { ContentContainer } from 'src/app/models/container';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../_core/error-handler/error-handler.service';

/**
 * ID: bh-navigation
 * Name: BH Navigation Service
 * Description: Service used for managing navigation, main menus, and account options
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2022-15-25 - MW - v1: Initial development
 */
@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  env = environment;

  // Define your pages for this app here
  homeNavPage: NavPage = {
    name: 'Home',
    navPath: '/tabs/home',
    tabPath: 'home',
    tabId: 'home',
    ionIcon: 'home',
    roles: ['USER', 'ADMIN', 'SYS_ADMIN']
  };

  eventsNavPage: NavPage = {
    name: 'Events',
    navPath: '/tabs/events',
    tabPath: 'events',
    tabId: 'events',
    ionIcon: 'calendar',
    roles: ['USER', 'ADMIN', 'SYS_ADMIN']
  };

  favoritesNavPage: NavPage = {
    name: 'Favorites',
    navPath: '/tabs/favorites',
    tabPath: 'favorites',
    tabId: 'favorites',
    ionIcon: 'star',
    roles: ['USER', 'ADMIN', 'SYS_ADMIN']
  };

  navPages: NavPage[] = [];
  classes: ContentContainer[] = [];
  homeClass: ContentContainer = {};
  homeRootCategory: ContentContainer = {};
  rootEventsApp: ContentContainer = {};
  rootEventsTab: ContentContainer = {};
  showNotifications = false;
  subscriptions: Subscription[] = [];
  accessiblePages = 0;
  navigationLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private deviceService: UserDeviceService,
    private containerService: ContainerService,
    private notifications: NotificationsService,
    private errorHandler: ErrorHandlerService
  ) {
   }

  checkPrivileges(authUser: User) {
    this.navPages.forEach(page => {
      if (page.roles && page.roles.length > 0) {
        if (authUser) {
          // User logged in, check roles
          const matches = page.roles.filter(r => (r && authUser && authUser.role && r.toLowerCase() === authUser.role.toLowerCase()));
          page.isAccessible = (matches.length > 0);
        } else {
          // User not logged in
          page.isAccessible = false;
        }
      } else {
        // Page unprotected
        page.isAccessible = true;
      }
    });

    this.accessiblePages = this.navPages.filter(p => p.isAccessible).length;
  }

  async loadNavigation(): Promise<boolean> {
    try {
      const res = await firstValueFrom(this.containerService.getAll('CLASS', null, true));
      if (res && Array.isArray(res) && res.length > 0) {
        this.classes = [];
        this.homeClass = res.find(r => r.id === this.env.homeClassId);
        this.classes = res.filter(r => r.id !== this.env.homeClassId);
        this.classes.sort((a, b) => a.sort - b.sort);

        // this.classes = res;
        firstValueFrom(this.containerService.getById(this.env.homeRootCategoryId)).then(catRes => {
          this.homeRootCategory = catRes;
        });
        firstValueFrom(this.containerService.getById(this.env.rootEventsAppId)).then(appRes => {
          this.rootEventsApp = appRes;
        });
        firstValueFrom(this.containerService.getById(this.env.rootEventsTabId)).then(tabRes => {
          this.rootEventsTab = tabRes;
        });
        // console.log('loadNavigation', this.homeClass, this.homeRootCategory);
        // this.classes = res;
        this.navPages.push(this.homeNavPage);
        for (const c of this.classes) {
          const navPage: NavPage = {
            name: c.name,
            navPath: '/tabs/explore/' + c.id,
            tabPath: 'explore/' + c.id,
            tabId: c.id,
            ionIcon: c.imageUrl ? c.imageUrl.replace('-outline', '') : '',
            roles: ['USER', 'ADMIN', 'SYS_ADMIN']
          }
          this.navPages.push(navPage);
        }
        this.navPages.push(this.eventsNavPage);
        this.navPages.push(this.favoritesNavPage);
        this.navigationLoaded.next(true);
        // console.log('classes', this.classes);
      }
    } catch (err) {
      this.errorHandler.handleError(err, 'loadNavigation');
    }
    return Promise.resolve(true);
  }

  navigateHome(): Promise<boolean> {
    return this.navigateBack('/tabs/home');
  }

  navigateForward(pathUrl, navOptions: NavigationOptions = undefined): Promise<boolean> {
    const userDevice = this.deviceService.getUserDevice();
    if (userDevice.isNarrowViewport) {
      return this.navCtrl.navigateForward(pathUrl, navOptions);
    } else {
      return this.navCtrl.navigateRoot(pathUrl, navOptions);
    }
  }

  navigateBack(pathUrl, navOptions: NavigationOptions = undefined): Promise<boolean> {
    const userDevice = this.deviceService.getUserDevice();
    if (userDevice.isNarrowViewport) {
      return this.navCtrl.navigateBack(pathUrl, navOptions);
    } else {
      return this.navCtrl.navigateRoot(pathUrl, navOptions);
    }
  }

  navigateRoot(pathUrl, navOptions: NavigationOptions = undefined): Promise<boolean> {
    return this.navCtrl.navigateRoot(pathUrl, navOptions);
  }

}
