<div class="content-type-selector">
  <div class="cts-label">{{ label }}</div>
  <div class="cts-options no-grid" *ngIf="readOnly">
    <div class="cts-selected-option">
      <ion-icon [name]="selectedContentType?.icon"></ion-icon>
      <div class="cts-option-label">{{ selectedContentType?.label }}</div>
    </div>
  </div>
  <div class="cts-options" *ngIf="!readOnly">
    <a
      href="javascript:void(0)"
      (click)="selectContentType(o)"
      [ngClass]="{ selected: o.value === selectedContentType?.value }"
      *ngFor="let o of contentTypeOptions"
    >
      <ion-icon [name]="o.icon"></ion-icon>
      <div class="cts-option-label">{{ o.label }}</div>
    </a>
  </div>
</div>
