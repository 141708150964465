import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Content } from 'src/app/models/content';
import { BhAnalyticsService } from '../_core/bhanalytics/bhanalytics.service';
import { ContentContainer } from 'src/app/models/container';
import { NavigationService } from '../navigation/navigation.service';
import { ContentViewerModalPage } from 'src/app/pages/content-viewer-modal/content-viewer-modal.page';
import { ContentService } from '../content/content.service';

@Injectable({
  providedIn: 'root'
})
export class ContentViewHandlerService {

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private analytics: BhAnalyticsService,
    private navService: NavigationService,
    private contentService: ContentService
  ) { }

  async openContent(
    c: Content,
    classContainer: ContentContainer = undefined,
    categoryContainer: ContentContainer = undefined,
    appContainer: ContentContainer = undefined
  ) {
    // console.log('contentViewer.openContent:', c);
    // TODO: Remove additional call once content icon field is added
    // Get full record until Oleksiy adds missing icon field
    // if (c.searchResultType) {
    //   const res = await firstValueFrom(this.contentService.getById(c.id, false));
    //   if (res && res.id) {
    //     c = res;
    //   }
    // }

    // Handle content type
    switch (c.type) {
      case 'LINK':
        const url = c.icon || c.url;
        if (
          !this.platform.is('mobile') &&
          url &&
          url.toLowerCase().indexOf('.pdf') > -1 &&
          url.toLowerCase().indexOf('.apps.baystatehealth.org') > -1
        ) {
          this.openPdf(url, c);
        } else {
          this.openLink(url);
        }
        break;

      case 'TANGO':
        this.openTango(c);
        break;

      case 'GOLIVE':
        this.navService.navigateForward('/tabs/golive/' + c.id);
        break;

      default:
        if (classContainer && categoryContainer && appContainer) {
          this.navService.navigateForward('/tabs/explore/' + classContainer.id + '/' + categoryContainer.id + '/' + appContainer.id + '/' + c.id);
        } else if (c.ClassContainer && c.SubCategoryContainer && c.AppContainer) {
          this.navService.navigateForward('/tabs/explore/' + c.ClassContainer.id + '/' + c.SubCategoryContainer.id + '/' + c.AppContainer.id + '/' + c.id);
        } else if (c.classContainerId && c.subCategoryContainerId && c.appContainerId) {
          this.navService.navigateForward('/tabs/explore/' + c.classContainerId + '/' + c.subCategoryContainerId + '/' + c.appContainerId + '/' + c.id);
        } else {
          this.navService.navigateForward('/tabs/content/' + c.id);
        }
    }
  }


  openLink(url: string) {
    if (this.platform.is('mobile')) {
      window.open(url, '_system');
    } else {
      window.open(url);
    }
  }

  async openPdf(url, c: Content) {
    // Create PDF viewer modal
    const modal = await this.modalCtrl.create({
      component: ContentViewerModalPage,
      cssClass: 'wide-modal pdf',
      componentProps: {
        content: c
      }
    });
    modal.onDidDismiss().then((data) => {
    });
    await modal.present();
  }

  async openTango(c: Content) {
    // QueryString
    // this.setUrlQueryString('content', c.id);

    // Create Tango viewer modal
    const modal = await this.modalCtrl.create({
      component: ContentViewerModalPage,
      cssClass: 'wide-modal pdf',
      componentProps: {
        content: c
      }
    });
    modal.onDidDismiss().then((data) => {
      // this.setUrlQueryString('', '');
    });
    await modal.present();
  }

}
