import { Component, OnInit, Input, OnChanges } from '@angular/core';
/**
 * ID: bh-spinner
 * Name: BH Spinner
 * Description: A loading spinner
 * Version: 3
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-06-23 - MW - v1: Initial dev
 * 2021-06-28 - MW - v2: Standardized look across ios and md
 * 2021-07-13 - MW - v3: Added spinner color option
 * 2022-05-09 - MW - v4: Updated spinner types and styles
 * 2023-12-19 - MW - v5: Added new skeleton types: boxes and tiles
 */

@Component({
  selector: 'bh-spinner',
  templateUrl: './bh-spinner.component.html',
  styleUrls: ['./bh-spinner.component.scss'],
})
export class BhSpinnerComponent implements OnChanges {
  @Input() type: 'spinner' | 'skeleton' | 'skeleton-boxes' | 'skeleton-tiles' = 'spinner';
  @Input() color = 'primary';
  @Input() skeletonItemCount = 3;
  @Input() message = null;
  skeletonArray = [];

  constructor() {
  }

  ngOnChanges() {
    for (let i = 0; i < this.skeletonItemCount; i++) {
      this.skeletonArray.push({});
    }
  }

}
