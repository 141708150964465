import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ManageContainerListPageRoutingModule } from './manage-container-list-routing.module';

import { ManageContainerListPage } from './manage-container-list.page';
import { BhSpinnerComponentModule } from 'src/app/components/_core/bh-spinner/bh-spinner.component.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { BhEditorContentListingComponentModule } from 'src/app/components/bh-editor-content-listing/bh-editor-content-listing.component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ManageContainerListPageRoutingModule,
    BhSpinnerComponentModule,
    PipesModule,
    BhEditorContentListingComponentModule
  ],
  declarations: [ManageContainerListPage]
})
export class ManageContainerListPageModule {}
