<h4 *ngIf="heading">{{ heading }}</h4>

<bh-spinner type="skeleton" *ngIf="!isLoaded"></bh-spinner>

<!-- Tabs -->
<div class="container-listing" *ngIf="showTabs">
  <ng-container *ngFor="let t of tabContainers">
    <a href="javascript:void(0)" class="cl-item tab" button>
      <div class="cl-item-inner">
        <ion-icon name="reorder-two-outline"></ion-icon>
        <ion-label>
          {{ t.name }}
        </ion-label>
        <ion-badge color="danger" *ngIf="!t.isActive">Inactive</ion-badge>
        <div class="content-edit">
          <a href="javascript:void(0)" (click)="editTab(t, $event)">
            <ion-icon name="pencil-outline"></ion-icon>
            Edit Tab
          </a>
        </div>
      </div>
    </a>
    <p class="no-content" *ngIf="t.childContent.length === 0">
      Tab doesn't contain content.
    </p>
    <ng-container *ngFor="let c of t.childContent">
      <a
        href="javascript:void(0)"
        class="cl-item tab-content-indent"
        (click)="viewContent(c, $event)"
        button
        *ngIf="!c.hide"
        [ngClass]="c.type"
      >
        <div class="cl-item-inner">
          <ion-icon
            name="rocket-outline"
            color="primary"
            *ngIf="c.type === 'GOLIVE'"
          ></ion-icon>
          <ion-icon
            name="document-text-outline"
            color="primary"
            *ngIf="c.type === 'HTML'"
          ></ion-icon>
          <ion-icon
            name="open-outline"
            color="primary"
            *ngIf="c.type === 'LINK'"
          ></ion-icon>
          <ion-icon
            name="film-outline"
            color="primary"
            *ngIf="c.type === 'VIDEO'"
          ></ion-icon>
          <ion-icon
            name="play-outline"
            color="primary"
            *ngIf="c.type === 'TANGO'"
          ></ion-icon>
          <ion-icon
            name="alert-circle-outline"
            color="primary"
            *ngIf="c.type === 'BANNER'"
          ></ion-icon>
          <div class="event-thumbnail" *ngIf="c.type === 'EVENT'">
            <span>{{ c.startDateTime | formatTimestamp : "MMM" }}</span>
            {{ c.startDateTime | formatTimestamp : "D" }}
          </div>
          <ion-label>
            {{ c.name }}
            <div class="details">
              <span class="status-indicator" [ngClass]="c.status"></span>
              {{ c.status }}
            </div>
            <div class="details">
              Updated: {{ c?.updatedAt | formatTimestamp: "M/D/YYYY" }}
            </div>
            <div class="details" *ngIf="!showTabs">
              {{ c?.AppContainer?.name }}
            </div>
            <div class="details" *ngIf="c.ContentTags">
              Sections: {{ c.ContentTags | formatContentTags : 'section' }}
            </div>
            <div class="details" *ngIf="c.ContentTags">
              Tags: {{ c.ContentTags | formatContentTags : 'tag' }}
            </div>
          </ion-label>
          <ion-badge color="danger" *ngIf="!c.isActive">Inactive</ion-badge>
          <div class="content-edit">
            <a href="javascript:void(0)" (click)="editContent(c, $event)">
              <ion-icon name="pencil-outline"></ion-icon>
              Edit Content
            </a>
            <!-- <a href="javascript:void(0)" (click)="viewContent(c, $event)">
              <ion-icon name="eye-outline"></ion-icon>
              View
            </a> -->
          </div>
        </div>
      </a>
    </ng-container>
  </ng-container>
</div>

<!-- No Tabs -->
<div class="container-listing" *ngIf="!showTabs">
  <ng-container *ngFor="let c of content">
    <a
      href="javascript:void(0)"
      class="cl-item"
      button
      *ngIf="!c.hide"
      [ngClass]="c.type"
    >
      <div class="cl-item-inner">
        <ion-icon
          name="rocket-outline"
          color="primary"
          *ngIf="c.type === 'GOLIVE'"
        ></ion-icon>
        <ion-icon
          name="document-text-outline"
          color="primary"
          *ngIf="c.type === 'HTML'"
        ></ion-icon>
        <ion-icon
          name="open-outline"
          color="primary"
          *ngIf="c.type === 'LINK'"
        ></ion-icon>
        <ion-icon
          name="film-outline"
          color="primary"
          *ngIf="c.type === 'VIDEO'"
        ></ion-icon>
        <ion-icon
          name="play-outline"
          color="primary"
          *ngIf="c.type === 'TANGO'"
        ></ion-icon>
        <ion-icon
          name="alert-circle-outline"
          color="primary"
          *ngIf="c.type === 'BANNER'"
        ></ion-icon>
        <div class="event-thumbnail" *ngIf="c.type === 'EVENT'">
          <span>{{ c.startDateTime | formatTimestamp : "MMM" }}</span>
          {{ c.startDateTime | formatTimestamp : "D" }}
        </div>
        <ion-label>
          {{ c.name }}
          <div class="details">{{ c?.AppContainer?.name }}</div>
          <div class="details">
            <span class="status-indicator" [ngClass]="c.status"></span>
            {{ c.status }}
          </div>
        </ion-label>
        <ion-badge color="danger" *ngIf="!c.isActive">Inactive</ion-badge>
        <div class="content-edit">
          <a href="javascript:void(0)" (click)="editContent(c, $event)">
            <ion-icon name="pencil-outline"></ion-icon>
            Edit
          </a>
          <a href="javascript:void(0)" (click)="viewContent(c, $event)">
            <ion-icon name="eye-outline"></ion-icon>
            View
          </a>
        </div>
      </div>
    </a>
  </ng-container>
  <p *ngIf="isLoaded && content?.length === 0">Content will display here.</p>
</div>
