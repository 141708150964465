import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { testApps } from 'src/app/models/_samples/testApps';
import { ContentContainer } from 'src/app/models/container';
import { User } from 'src/app/models/user';
import { ManageContainerEditorPage } from 'src/app/pages/manage-container-editor/manage-container-editor.page';
import { ManageContainerListPage } from 'src/app/pages/manage-container-list/manage-container-list.page';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { ErrorHandlerService } from 'src/app/services/_core/error-handler/error-handler.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Component({
  selector: 'bh-category-container',
  templateUrl: './bh-category-container.component.html',
  styleUrls: ['./bh-category-container.component.scss'],
})
export class BhCategoryContainerComponent implements OnChanges {
  @Input() categoryContainer;
  @Input() classContainer;
  @Input() filterTerm: string;
  @Output() moveEvent = new EventEmitter();
  @Output() loadedEvent = new EventEmitter();
  appContainers: ContentContainer[] = [];
  initAppContainers: ContentContainer[] = [];
  authUser: User;
  saveTimeout = null;
  isLoading = false;

  constructor(
    private authService: AuthService,
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private navService: NavigationService,
    private containerService: ContainerService,
    private notifications: NotificationsService,
    private errorHandler: ErrorHandlerService

  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if ('filterTerm' in changes && this.filterTerm) {
      this.filterApps();
    } else {
      this.authUser = this.authService.getAuthUser();
      this.loadChildApps();
    }
  }

  async loadChildApps() {
    try {
      this.isLoading = true;
      const res = await firstValueFrom(this.containerService.getAll('APP', this.categoryContainer.id, true));
      if (res && Array.isArray(res) && res.length > 0) {
        this.appContainers = res;

        // Sort
        this.appContainers.sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        });

        // Set init
        this.initAppContainers = Object.assign([], this.appContainers);

        // Filter
        this.filterApps();
      }

      // Report back loaded apps
      this.loadedEvent.emit(Object.assign([], this.initAppContainers));

    } catch (err) {
      this.errorHandler.handleError(err, 'loadChildApps');
    }
    this.isLoading = false;
  }

  filterApps() {
    if (this.filterTerm) {
      this.appContainers = this.initAppContainers.filter(ia => ia.name.toLowerCase().indexOf(this.filterTerm.toLowerCase()) > -1);
    } else {
      // Removed TechTalks from category index
      this.appContainers = Object.assign([], this.initAppContainers.filter(a => a.name !== 'TechTalks'));
    }
  }

  openApp(app: ContentContainer) {
    this.analytics.clickEvent('category-container: open-app', app.name);
    setTimeout(() => {
      this.navService.navigateForward('/tabs/explore/' + this.classContainer.id + '/' + this.categoryContainer.id + '/' + app.id);
    }, 200);
  }

  async addApp() {
    this.analytics.clickEvent('Category > Add App', this.categoryContainer.name);
    const modal = await this.modalCtrl.create({
      component: ManageContainerEditorPage,
      cssClass: 'wide-modal',
      componentProps: {
        editMode: 'new',
        containerType: 'APP',
        parentId: this.categoryContainer.id
      }
    });

    modal.onDidDismiss().then((data) => {
      this.loadChildApps();
    });

    await modal.present();
  }

  async manageApps() {
    const topModal: HTMLIonModalElement = await this.modalCtrl.getTop();
    if (topModal) {
      topModal.dismiss();
    }
    const modal = await this.modalCtrl.create({
      component: ManageContainerListPage,
      cssClass: 'wide-modal',
      componentProps: {
        scope: 'app',
        parentId: this.categoryContainer.id
      }
    });

    modal.onDidDismiss().then((data) => {
      this.loadChildApps();
    });

    await modal.present();
  }

  async editCategory() {
    this.analytics.clickEvent('Category > Edit', this.categoryContainer.name);
    const modal = await this.modalCtrl.create({
      component: ManageContainerEditorPage,
      cssClass: 'wide-modal',
      componentProps: {
        editMode: 'edit',
        containerType: 'CATEGORY',
        containerId: this.categoryContainer.id
      }
    });

    modal.onDidDismiss().then((data) => {
      this.loadChildApps();
    });

    await modal.present();

  }

  moveCategory(direction) {
    const moveEvent = { container: this.categoryContainer, direction };
    this.moveEvent.emit(moveEvent);
  }

  async editApp(appContainer: ContentContainer) {
    console.log('bh-category-container: editApp', appContainer);
    this.analytics.clickEvent('App > Edit', appContainer.name);
    const modal = await this.modalCtrl.create({
      component: ManageContainerEditorPage,
      cssClass: 'wide-modal',
      componentProps: {
        editMode: 'edit',
        containerType: 'APP',
        containerId: appContainer.id
      }
    });

    modal.onDidDismiss().then((data) => {
      this.loadChildApps();
    });

    await modal.present();

  }

  moveApp(appContainer: ContentContainer, direction: 'up' | 'down', ev) {
    ev.stopPropagation();
    // console.log('sorting', appContainer, direction);
    if (appContainer && direction) {
      let preIndex = 0;
      for (const t of this.appContainers) {
        t.sort = preIndex;
        preIndex += 1;
      }

      // Increment
      for (const c of this.appContainers) {
        if (c.id === appContainer.id) {
          if (direction === 'up') {
            c.sort += -1.5;
          } else {
            c.sort += 1.5;
          }
          // c.sort += (moveEvent.direction === 'up' ? -0.5 : 0.5);
          // console.log('incrementing', c);
        }
      }

      // console.log('incremented: ', this.appContainers);

      // Sort
      this.appContainers.sort((a, b) => a.sort - b.sort);

      // console.log('sorted: ', this.appContainers);

      // Update sort indices
      let index = 0;
      for (const c of this.appContainers) {
        c.sort = index;
        index += 1;
      }

      // console.log('reordered: ', this.appContainers);

      // TODO: Save sort
      this.saveContainers();
    }
  }

  async saveContainers() {
    if (this.saveTimeout !== null) {
      clearTimeout(this.saveTimeout);
    }
    this.saveTimeout = setTimeout(() => {
      // TODO: Save sort
      this.appContainers.forEach(async c => {
        try {
          const res = await firstValueFrom(this.containerService.update(c));
          // console.log('saveContainers: ', res);
        } catch (err) {
          console.error('Saving containers', err);
        }
      });
    }, 250)
  }
}
