import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { User } from 'src/app/models/user';
import { UserActivity } from 'src/app/models/user-activity';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { UserActivityService } from 'src/app/services/user-activity/user-activity.service';
import * as moment from 'moment';
import { ErrorHandlerService } from 'src/app/services/_core/error-handler/error-handler.service';

@Component({
  selector: 'bh-content-rater',
  templateUrl: './bh-content-rater.component.html',
  styleUrls: ['./bh-content-rater.component.scss'],
})
export class BhContentRaterComponent implements OnChanges {
  @Input() userActivity: UserActivity;
  @Output() feedbackSubmitted = new EventEmitter();
  isHelpful: boolean | null = null;
  comment = '';
  readOnly = false;
  authUser: User;

  constructor(
    private activityService: UserActivityService,
    private notifications: NotificationsService,
    private errorHandler: ErrorHandlerService,
    private authService: AuthService,
    private analytics: BhAnalyticsService
  ) { }

  ngOnChanges() {
    this.authUser = this.authService.getAuthUser();
    this.isHelpful = this.userActivity.isHelpful;
    this.comment = this.userActivity.feedbackComment;
    this.readOnly = !!this.comment;
  }

  rate(isHelpful) {
    this.analytics.clickEvent('content-rater: is-helpful', this.userActivity.id + '|' + isHelpful);
    if (isHelpful !== null) {
      if (isHelpful !== this.userActivity.isHelpful) {
        this.isHelpful = isHelpful;
        this.userActivity.isHelpful = this.isHelpful;
        this.saveUserActivity();
        this.readOnly = false;
      } else {
        this.readOnly = true;
      }
    }
  }

  submitFeedback() {
    this.analytics.clickEvent('content-rater: comment', this.userActivity.id + '|' + this.comment);
    if (this.comment) {
      this.userActivity.feedbackComment = this.comment;
      this.userActivity.feedbackDateTime = moment().toISOString(true);
      this.saveUserActivity();
      this.notifications.showToast('Feedback submitted');
      this.feedbackSubmitted.emit(true);
    }
  }

  editFeedback() {
    this.readOnly = false;
  }

  async saveUserActivity() {
    try {
      const res = firstValueFrom(this.activityService.update(this.authUser, this.userActivity));
      if (res) {
        // console.log('Feedback captured', res);
        this.readOnly = true;
      }
    } catch (err) {
      this.errorHandler.handleError(err, 'content-rater.saveUserActivity()');
    }
  }

}
