import { Component, Input, OnInit } from '@angular/core';
import { Content } from 'src/app/models/content';
import { UserActivity } from 'src/app/models/user-activity';

@Component({
  selector: 'app-content-viewer-modal-ellipsis-menu',
  templateUrl: './content-viewer-modal-ellipsis-menu.page.html',
  styleUrls: ['./content-viewer-modal-ellipsis-menu.page.scss'],
})
export class ContentViewerModalEllipsisMenuPage implements OnInit {
  @Input() content: Content;
  @Input() userActivity: UserActivity;

  constructor() { }

  ngOnInit() {
  }

}
