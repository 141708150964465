<a
  href="javascript:void(0)"
  (click)="openApp()"
  class="app-tile"
  [title]="appContainer?.description"
  [ngClass]="{
    'edit-mode':
      (showEditButton || showMoveButtons) &&
      !authUser?.presentationMode &&
      (authUser?.role === 'ADMIN' || authUser?.role === 'SYS_ADMIN')
  }"
>
  <!--   *ngFor="let a of appContainers  -->
  <div class="editor-toolbar">
    <a
      href="javascript:void(0)"
      id="open-modal"
      (click)="editApp($event)"
      *ngIf="showEditButton"
      ><ion-icon name="pencil-outline"></ion-icon> Edit App</a
    >
    <a
      href="javascript:void(0)"
      id="open-modal"
      (click)="moveApp('up', $event)"
      *ngIf="showMoveButtons"
      ><ion-icon name="arrow-back-outline"></ion-icon
    ></a>
    <a
      href="javascript:void(0)"
      id="open-modal"
      (click)="moveApp('down', $event)"
      *ngIf="showMoveButtons"
      ><ion-icon name="arrow-forward-outline"></ion-icon
    ></a>
  </div>
  <div
    class="app-tile-image"
    [ngClass]="{ 'no-thumbnail': !appContainer?.imageUrl }"
    [ngStyle]="{
      'background-image': 'url(\'' + appContainer?.imageUrl + '\')'
    }"
  >
    <span *ngIf="!appContainer?.imageUrl">{{ appContainer?.name }}</span>
    <span class="audience-badge" *ngIf="showAudienceBadge && appContainer?.audience"
      >For {{ appContainer?.audience }}</span
    >
  </div>
  <div class="app-tile-name">
    <span>{{ appContainer?.name }}</span>
    <span class="app-tile-description truncate">{{ appContainer?.featuredDetail }}</span>
  </div>
</a>
