import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, catchError, of, throwError } from 'rxjs';
import { ContentTag } from 'src/app/models/tag';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentSectionService {

  constructor(
    private http: HttpClient,
  ) { }

  getAll(): Observable<ContentTag[]> {
    const url = `${environment.apiUrl}/content-sections`;
    return this.http.get(url).pipe(
      map((data: any) => data),
      // catchError((err) => {
      //   return of(err);
      // })
    );
  }

  getByContentTagTabIds(contentId, tagId, tabContainerId): Observable<any> {
    const url = `${environment.apiUrl}/content-sections/contents/${contentId}/tags/${tagId}/containers/${tabContainerId}`;
    return this.http.get(url).pipe(
      map((data: any) => data),
      // catchError((err) => {
      //   return of(err);
      // })
    );
  }

  create(contentTag: ContentTag): Observable<ContentTag> {
    const url = `${environment.apiUrl}/content-sections`;
    const body = this.prepareBody(contentTag, false);
    return this.http.post(url, body).pipe(
      map((data: any) => data),
      // catchError((err) => {
      //   return of(err);
      // })
    );
  }

  update(contentTag: ContentTag): Observable<ContentTag> {
    // console.log('*** content-section.service:', contentTag);
    const url = `${environment.apiUrl}/content-sections/contents/${contentTag.contentId}/tags/${contentTag.tagId}/containers/${contentTag.tabContainerId}`;
    const body = this.prepareBody(contentTag, true);
    // console.log('*** content-section.service:', body);
    return this.http.patch(url, body).pipe(
      map((data: any) => data),
      // catchError((err) => {
      //   return of(err);
      //   // throw Error(err);
      // })
    );
  }

  prepareBody(contentTag: ContentTag, isUpdate: boolean): any {
    const body = Object.assign({}, contentTag);
    delete body.createdAt;
    delete body.createdBy;
    delete body.CreatedByUser;
    delete body.updatedAt;
    delete body.updatedBy;
    delete body.UpdatedByUser;
    delete body.Tag;
    delete body.tabContainer;
    delete body.TabContainer;
    delete body.Content;

    if (isUpdate) {
      delete body.tagId;
      delete body.contentId;
      delete body.tabContainerId
    }
    return body;
  }

  private handleError(error: HttpErrorResponse) {
    let userFacingErrorMessage = 'Something bad happened; please try again later.';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      console.error(`Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error(userFacingErrorMessage));
  }

}
