import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { Tag } from 'src/app/models/tag';
import { TagType } from 'src/app/models/tag-type';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { }

  getAll(activeOnly?: boolean, skip?: number, take?: number) {
    const qryActiveOnly = activeOnly ? `&activeOnly=${activeOnly}` : '';
    const qrySkip = skip && !Number.isNaN(skip) ? `&skip=${skip}` : '';
    const qryTake = take && !Number.isNaN(take) ? `&take=${take}` : '';
    const url = `${environment.apiUrl}/tags?${qryActiveOnly}${qrySkip}${qryTake}`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }

  getByName(name?: string, activeOnly?: boolean, skip?: number, take?: number) {
    const qryActiveOnly = activeOnly ? `&activeOnly=${activeOnly}` : '';
    const qrySkip = skip && !Number.isNaN(skip) ? `&skip=${skip}` : '';
    const qryTake = take && !Number.isNaN(take) ? `&take=${take}` : '';
    const qryName = encodeURIComponent(name);
    const url = `${environment.apiUrl}/tags?name=${qryName}${qryActiveOnly}${qrySkip}${qryTake}`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }

  getById(tagId: string) {
    const url = `${environment.apiUrl}/tags/${tagId}`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }

  create(tag: Tag) {
    // console.log('tag service: tag created', tag);
    const url = `${environment.apiUrl}/tags`;
    const body = this.prepareBody(tag);
    return this.http.post(url, body).pipe(
      map((data: any) => data),
    );
  }

  update(tag: Tag) {
    // console.log('tag service: updated tag', tag);
    const url = `${environment.apiUrl}/tags/${tag.id}`;
    const body = this.prepareBody(tag);
    return this.http.patch(url, body).pipe(
      map((data: any) => data),
    );
  }

  prepareBody(tag: Tag): any {
    const body = Object.assign({}, tag);
    delete body.id;
    // delete body.type;
    delete body.createdAt;
    delete body.createdBy;
    delete body.CreatedByUser;
    delete body.updatedAt;
    delete body.updatedBy;
    delete body.UpdatedByUser;
    delete body.hide;
    return body;
  }


  translateTagTypeCode(type: TagType) {
    switch (type) {
      case 'TAG':
        return 'Tag';

      case 'SECTION':
        return 'Section';

      case 'GOLIVE':
        return 'Go-Live Content';

    }
  }


}
