import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BhAppLogoComponentModule } from '../_core/bh-app-logo/bh-app-logo.component.module';
import { BhUserBadgeComponentModule } from '../_core/bh-user-badge/bh-user-badge.component.module';
import { BhSpinnerComponentModule } from '../_core/bh-spinner/bh-spinner.component.module';
import { QuillModule } from 'ngx-quill';
import { BhEditorToolboxComponentModule } from '../bh-editor-toolbox/bh-editor-toolbox.component.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { BhAppTileComponent } from './bh-app-tile.component';

@NgModule({
  declarations: [
    BhAppTileComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BhAppLogoComponentModule,
    BhUserBadgeComponentModule,
    BhSpinnerComponentModule,
    QuillModule.forRoot(),
    BhEditorToolboxComponentModule,
    PipesModule
  ],
  exports: [
    BhAppTileComponent
  ]
})
export class BhAppTileComponentModule { }
