import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { ContentContainer } from 'src/app/models/container';
import { ContainerType } from 'src/app/models/container-type';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { ManageContainerEditorPage } from '../manage-container-editor/manage-container-editor.page';
import { ContentService } from 'src/app/services/content/content.service';
import { Content } from 'src/app/models/content';
import { ExplorerObject } from 'src/app/models/explorer-object';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { ManageContentEditorPage } from '../manage-content-editor/manage-content-editor.page';
import { ContentViewHandlerService } from 'src/app/services/content-view-handler/content-view-handler.service';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from 'src/app/services/_core/error-handler/error-handler.service';

@Component({
  selector: 'app-manage-container-list',
  templateUrl: './manage-container-list.page.html',
  styleUrls: ['./manage-container-list.page.scss'],
})
export class ManageContainerListPage implements OnInit {
  @Input() scope: 'global' | 'category' | 'app' | 'tab' | 'golive' | 'featured' = 'global';
  // @Input() showContent = false;
  // @Input() selectMode: 'select' | 'edit' = 'edit';
  @Input() parentId: string = null;
  env = environment;
  explorerObjects: ExplorerObject[] = [];
  initContainers: ContentContainer[] = [];
  containers: ContentContainer[] = [];
  initContent: Content[] = [];
  content: Content[] = [];
  modeLabel = 'Containers';
  segmentTitle = '';
  activeSegment: 'all' | 'class' | 'category' | 'app' | 'content' | 'featured' = 'all';
  searchTerm = '';
  isLoading = true;
  orderedItems: string[] = [];
  unsavedReorder = false;
  showSavedMessage = false;
  showResetMessage = false;

  segments = [
    {
      id: 'all',
      label: 'Browse',
      icon: 'folder-outline'
    },
    {
      id: 'featured',
      label: 'Featured Apps',
      icon: 'sunny-outline'
    },
    {
      id: 'category',
      label: 'All Categories',
      icon: 'apps-outline'
    },
    {
      id: 'app',
      label: 'All Apps',
      icon: 'browsers-outline'
    },
    {
      id: 'tab',
      label: 'All Tabs',
      icon: 'reorder-two-outline'
    },
    {
      id: 'content',
      label: 'All Content',
      icon: 'document-text-outline'
    },

  ];

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private navService: NavigationService,
    private containerService: ContainerService,
    private contentService: ContentService,
    private contentViewer: ContentViewHandlerService,
    private notifications: NotificationsService,
    private errorHandler: ErrorHandlerService
  ) { }

  async ngOnInit() {
    this.setModeLabel();
    await this.loadData();
    // console.log('ngOnInit: containers: ', this.containers);
  }

  setModeLabel() {
    switch (this.scope) {
      case 'tab':
        this.modeLabel = 'Tabs';
        break;

      case 'featured':
        this.modeLabel = 'Featured Apps';
        break;
    }
  }

  async loadData(): Promise<boolean> {
    this.isLoading = true;
    this.clearContainers();
    if (this.scope === 'golive') {
      await this.loadContainerType('GOLIVE');
    } else {
      await this.loadContainerType('CLASS');
      await this.loadContainerType('CATEGORY');
      await this.loadContainerType('APP');
      await this.loadContainerType('TAB');
      await this.loadContent();
    }
    this.setScope();
    this.getParentContainers();
    this.containers = Object.assign([], this.initContainers);
    this.sortContainers();
    this.filterByContainerType(this.activeSegment);
    this.isLoading = false;
    return Promise.resolve(true);
  }

  setScope() {
    // console.log('setting scope', this.scope)
    switch (this.scope) {
      case 'category':
        // console.log('setting scope: category')
        this.activeSegment = 'category';
        this.filterByContainerType('category');
        break;

      case 'app':
        this.activeSegment = 'app';
        this.filterByContainerType('app');
        break;

      case 'featured':
        this.activeSegment = 'featured';
        this.filterByContainerType('featured');
        break;

      case 'golive':
        this.activeSegment = 'app';
        this.filterByContainerType('golive');
        break;

    }
  }

  doReorder(ev: any) {
    console.log('$event from reorder', ev);
    const ogOrder = Object.assign([], this.orderedItems);
    const itemMove = this.orderedItems.splice(ev.detail.from, 1)[0];
    console.log('Moving item', itemMove);
    this.orderedItems.splice(ev.detail.to, 0, itemMove);
    console.log('Ordered items', this.orderedItems);
    ev.detail.complete(true);
    this.orderedItems = ev.detail.complete(this.orderedItems);
    this.unsavedReorder = true;
    console.log('orderedItems from doReorder', ogOrder, this.orderedItems, ogOrder === this.orderedItems);
  }

  async saveOrder() {
    try {
      let index = 0;
      for (const id of this.orderedItems) {
        const container = Object.assign({}, this.containers.find(c => c.id === id));
        container.featuredSort = index;
        console.log('Saving', id, container, container.featuredSort, index);
        await firstValueFrom(this.containerService.update(container));
        index += 1;
      }
      this.showSavedMessage = true;
      setTimeout(() => {
        this.unsavedReorder = false;
        setTimeout(() => {
          this.showSavedMessage = false;
        }, 500);
      }, 1500);
    } catch (err) {
      this.errorHandler.handleError(err, 'saveOrder');
    }
  }

  resetOrder() {
    this.showResetMessage = true;
    this.loadData();
    setTimeout(() => {
      this.unsavedReorder = false;
      setTimeout(() => {
        this.showResetMessage = false;
      }, 500);
    }, 1500);
  }

  stopPropagation(ev) {
    ev.stopPropagation();
  }

  clearContainers() {
    this.initContainers = [];
    this.containers = [];
  }

  async loadContainerType(containerType: ContainerType) {
    try {
      const parentId = this.scope !== 'global' ? this.parentId : null;
      const res = await firstValueFrom(this.containerService.getAll(containerType, parentId, false));
      if (res && Array.isArray(res) && res.length > 0) {
        const containers = res;
        this.initContainers = this.initContainers.concat(containers);
        // console.log('loadContainerType', this.initContainers);
      }
      return Promise.resolve(true);
      // this.classes = this.testClasses;
    } catch (err) {
      this.errorHandler.handleError(err, 'loadContainerType');
      return Promise.reject();
    }
  }

  async loadContent() {
    try {
      const res = await firstValueFrom(this.contentService.getAll(null, false));
      if (res && Array.isArray(res) && res.length > 0) {
        const content = res.filter(r => r.type !== 'EVENT');
        this.initContent = content;
        this.content = Object.assign([], content);
        // console.log('loadContent', this.initContent);
      }
      return Promise.resolve(true);
      // this.classes = this.testClasses;
    } catch (err) {
      this.errorHandler.handleError(err, 'loadContent');
      return Promise.reject();
    }
  }

  getParentContainers() {
    if (this.scope === 'global') {
      for (const c of this.initContainers) {
        const parent = this.initContainers.find(ic => c.parentId === ic.id);
        c.parentName = parent !== undefined ? parent.name : '(No parent)';
        c.hide = false;
      }
    }
  }

  sortContainers() {
    if (this.scope === 'global') {
      const parentContainers: ContentContainer[] =
        this.initContainers.filter(ic =>
          ic.parentId === null &&
          ic.id === this.env.appsId &&
          ic.type === 'CLASS'
        );
      parentContainers.sort((a, b) => this.sortContainersByName(a, b));
      const sortedContainers: ContentContainer[] = [];
      const explorerObjects: ExplorerObject[] = [];
      // console.log('sortContainers: parentContainers', parentContainers);

      // File Classes
      let index = 0.0;
      for (const pc of parentContainers) {
        index += 1.0;
        pc.viewSort = index;
        const eo = this.containerService.setContainerExploreObject(pc, index);
        sortedContainers.push(pc);
        const childCategories = this.initContainers.filter(ic => ic.parentId === pc.id);
        childCategories.sort((a, b) => this.sortContainersByName(a, b));
        // eo.children = this.setContainerExplorerObjectChildren(childCategories);
        // console.log('sortContainers: childCategories', childCategories);

        // File Categories
        let catIndex = 0.0;
        for (const cc of childCategories) {
          catIndex += 0.1;
          cc.ClassContainer = pc;
          cc.viewSort = index + catIndex;
          const catEo = this.containerService.setContainerExploreObject(cc, catIndex);
          sortedContainers.push(cc);
          const childApps = this.initContainers.filter(ca => ca.parentId === cc.id);
          childApps.sort((a, b) => this.sortContainersByName(a, b));
          // catEo.children = this.setContainerExplorerObjectChildren(childApps);
          // console.log('sortContainers: appCategories', childApps);

          // File Apps
          let appIndex = 0.00;
          for (const ca of childApps) {
            appIndex += 0.01;
            ca.ClassContainer = pc;
            ca.SubCategoryContainer = cc;
            ca.viewSort = index + catIndex + appIndex;
            const appEo = this.containerService.setContainerExploreObject(ca, appIndex);
            sortedContainers.push(ca);
            const childTabs = this.initContainers.filter(ct => ct.parentId === ca.id);
            childTabs.sort((a, b) => this.sortContainersByName(a, b));
            // appEo.children = this.setContainerExplorerObjectChildren(childTabs);

            // File Tabs
            let tabIndex = 0.000;
            for (const ct of childTabs) {
              tabIndex += 0.001;
              ct.ClassContainer = pc;
              ct.SubCategoryContainer = cc;
              ct.AppContainer = ca;
              ct.viewSort = index + catIndex + appIndex + tabIndex;
              const tabEo = this.containerService.setContainerExploreObject(ct, tabIndex);
              // console.log('sortContainers: childTabs: applying sort', ct);
              sortedContainers.push(ct);
              const childContent = this.initContent.filter(c => c.parentId === ct.id);
              // console.log('childContent', ct.id, this.initContent, childContent)
              tabEo.children = this.setContentExplorerObjectChildren(childContent);
              appEo.children.push(tabEo);
            }
            catEo.children.push(appEo)
          }
          eo.children.push(catEo)
        }
        explorerObjects.push(eo);
      }
      sortedContainers.sort((a, b) => a.sort - b.sort);
      this.initContainers = sortedContainers;
      this.explorerObjects = explorerObjects;
      // console.log('sortContainers: explorerObjects', explorerObjects, sortedContainers);
    } else {
      this.containers.sort((a, b) => this.sortContainersByName(a, b));
      // console.log('sorting containers', this.containers);
    }
  }

  setContainerExplorerObjectChildren(childrenContainers: ContentContainer[]) {
    let index = 0;
    const eoChildren: ExplorerObject[] = [];
    for (const cc of childrenContainers) {
      index += 1;
      const childEo = this.containerService.setContainerExploreObject(cc, index);
      eoChildren.push(childEo);
    }
    return eoChildren;
  }

  setContentExplorerObjectChildren(childrenContent: Content[]) {
    let index = 0;
    const eoChildren: ExplorerObject[] = [];
    for (const cc of childrenContent) {
      index += 1;
      const childEo = this.contentService.setContentExploreObject(cc, index);
      eoChildren.push(childEo);
    }
    return eoChildren;
  }

  async edit(container: ContentContainer, ev) {
    ev.stopPropagation();
    // console.log('editing', container);
    this.analytics.clickEvent('Manage-Containers > Edit', container.name);
    const modal = await this.modalCtrl.create({
      component: ManageContainerEditorPage,
      cssClass: 'wide-modal',
      componentProps: {
        editMode: 'edit',
        containerType: container.type,
        containerId: container.id
      }
    });

    modal.onDidDismiss().then((val) => {
      this.loadData();
    });

    await modal.present();
  }

  async editContent(content: Content, ev) {
    ev.stopPropagation();
    // console.log('editing', content);
    this.analytics.clickEvent('Manage-Containers > Edit', content.name);
    const modal = await this.modalCtrl.create({
      component: ManageContentEditorPage,
      cssClass: 'wide-modal',
      componentProps: {
        editMode: 'edit',
        classContainerId: content.ClassContainer.id,
        categoryContainerId: content.SubCategoryContainer.id,
        appContainerId: content.AppContainer.id,
        tabContainerId: content.TabContainer.id,
        parentContainerId: content.TabContainer.id,
        contentType: content.type,
        contentId: content.id,
      }
    });

    modal.onDidDismiss().then((val) => {
      this.loadData();
    });

    await modal.present();
  }


  async view(container: ContentContainer, classContainer: ContentContainer, categoryContainer: ContentContainer, ev) {
    // console.log('view: ', container, classContainer, categoryContainer);
    let classContainerId: string;
    let categoryContainerId: string;

    ev.stopPropagation();
    switch (container.type) {
      case 'CLASS':
        this.modalCtrl.dismiss();
        this.navService.navigateForward('/tabs/explore/' + container.id);
        break;

      case 'CATEGORY':
        this.modalCtrl.dismiss();
        this.navService.navigateForward('/tabs/explore/' + container.parentId);
        break;

      case 'APP':
        this.modalCtrl.dismiss();
        classContainerId = !classContainer ? container.ClassContainer.id : classContainer.id;
        categoryContainerId = !categoryContainer ? container.SubCategoryContainer.id : categoryContainer.id;
        this.navService.navigateForward('/tabs/explore/' + classContainerId + '/' + categoryContainerId + '/' + container.id);
        break;

      case 'TAB':
        this.modalCtrl.dismiss();
        classContainerId = !classContainer ? container.ClassContainer.id : classContainer.id;
        categoryContainerId = !categoryContainer ? container.SubCategoryContainer.id : categoryContainer.id;
        this.navService.navigateForward('/tabs/explore/' + classContainerId + '/' + categoryContainerId + '/' + container.parentId);
        break;

      case 'GOLIVE':
        this.modalCtrl.dismiss();
        this.navService.navigateForward('/tabs/golive/' + container.id);
        break;

    }
  }

  viewContent(c: Content, ev) {
    ev.stopPropagation();
    this.analytics.clickEvent('manage-container-list: content', c.name);
    this.contentViewer.openContent(c);
    // switch (c.type) {
    //   case 'LINK':
    //     this.openLink(c.icon || c.url);
    //     break;

    //   case 'GOLIVE':
    //     this.navService.navigateForward('/tabs/golive/' + c.id);
    //     break;

    //   default:
    //     if (c.ClassContainer && c.SubCategoryContainer && c.AppContainer) {
    //       this.navService.navigateForward('/tabs/explore/' + c.ClassContainer.id + '/' + c.SubCategoryContainer.id + '/' + c.AppContainer.id + '/' + c.id);
    //     } else if (c.ClassContainer && c.SubCategoryContainer && c.AppContainer) {
    //       this.navService.navigateForward('/tabs/explore/' + c.ClassContainer.id + '/' + c.SubCategoryContainer.id + '/' + c.AppContainer.id + '/' + c.id);
    //     } else {
    //       this.navService.navigateForward('/tabs/content/' + c.id);
    //     }
    // }
  }

  // openLink(url) {
  //   if (this.platform.is('mobile')) {
  //     window.open(url, '_system');
  //   } else {
  //     window.open(url);
  //   }
  // }


  async toggleExpand(eo: ExplorerObject, ev) {
    ev.stopPropagation();
    eo.isExpanded = !eo.isExpanded;
  }

  filterByContainerType(ev) {
    // console.log('filterByContainerType: ev', ev);
    const activeSegmentId: string = ev && ev.detail ? ev.detail.value : ev;
    const segment = this.segments.find(s => s.id === activeSegmentId);
    this.segmentTitle = segment.label;
    if (activeSegmentId === 'all') {
      this.containers = Object.assign([], this.initContainers);
      this.containers.sort((a, b) => this.sortContainersByName(a, b));
    } else {
      // console.log('Filtering', ev);
      if (activeSegmentId === 'featured') {
        this.containers = this.initContainers.filter(ic => ic.type === 'APP' && ic.featured === 'HOME' && ic.isActive);
        this.containers.sort((a, b) => a.featuredSort - b.featuredSort);
        for (const c of this.containers) {
          this.orderedItems.push(c.id);
        }
        this.search(this.searchTerm);
      } else {
        const containerType = activeSegmentId.toUpperCase();
        this.containers = this.initContainers.filter(ic => ic.type === containerType);
        this.containers.sort((a, b) => this.sortContainersByName(a, b));
        this.search(this.searchTerm);
      }
    }
  }

  sortContainersByName(a, b) {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  }

  search(ev) {
    this.searchTerm = (ev.detail) ? ev.detail.value : this.searchTerm;
    const qry = this.searchTerm.toLowerCase();
    if (qry) {
      if (this.activeSegment === 'content') {
        for (const c of this.content) {
          c.hide = c.name.toLowerCase().indexOf(qry) === -1;
        }
      } else {
        for (const c of this.containers) {
          c.hide = c.name.toLowerCase().indexOf(qry) === -1;
        }
      }
    } else {
      for (const c of this.containers) {
        c.hide = false;
      }
      for (const c of this.content) {
        c.hide = false;
      }

    }
    // console.log('search', this.searchTerm);
  }

  dismiss() {
    this.analytics.clickEvent('manage-container-list: dismiss', '');
    this.modalCtrl.dismiss();
  }
}
