import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BhEditorContentListingComponent } from './bh-editor-content-listing.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { BhSpinnerComponentModule } from '../_core/bh-spinner/bh-spinner.component.module';

@NgModule({
  declarations: [BhEditorContentListingComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    BhSpinnerComponentModule
  ],
  exports: [BhEditorContentListingComponent],
})
export class BhEditorContentListingComponentModule { }
