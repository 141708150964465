<div
  class="category-container"
  *ngIf="!filterTerm || (filterTerm && appContainers.length > 0)"
  [ngClass]="{
    'edit-mode':
      !authUser.presentationMode &&
      (authUser.role === 'ADMIN' || authUser.role === 'SYS_ADMIN')
  }"
>
  <div class="editor-toolbar">
    <a href="javascript:void(0)" id="open-modal" (click)="addApp()"
      ><ion-icon name="add-outline"></ion-icon> Add App</a
    >
    <a href="javascript:void(0)" id="open-modal" (click)="manageApps()"
      ><ion-icon name="list-circle-outline"></ion-icon> All Apps</a
    >
    <a href="javascript:void(0)" id="open-modal" (click)="editCategory()"
      ><ion-icon name="pencil-outline"></ion-icon> Edit Category</a
    >
    <!-- <a href="javascript:void(0)" id="open-modal" (click)="moveCategory('up')"
      ><ion-icon name="arrow-up-outline"></ion-icon
    ></a>
    <a href="javascript:void(0)" id="open-modal" (click)="moveCategory('down')"
      ><ion-icon name="arrow-down-outline"></ion-icon
    ></a> -->
  </div>

  <bh-spinner
    type="skeleton-tiles"
    [skeletonItemCount]="3"
    *ngIf="isLoading"
  ></bh-spinner>

  <div class="cc-heading">
    <div
      class="cc-heading-thumbnail"
      [ngStyle]="{
        'background-image': 'url(\'' + categoryContainer?.imageUrl + '\')'
      }"
    ></div>
    <div class="cc-heading-text">{{ categoryContainer.name }}</div>
  </div>
  <div
    class="cc-listing-empty"
    *ngIf="!isLoading && appContainers && appContainers.length === 0"
  >
    {{ categoryContainer.name }} content will display here.
  </div>
  <div class="cc-listing">
    <bh-app-tile
      *ngFor="let app of appContainers"
      analyticsSource="Apps > Category"
      [appContainer]="app"
      [showEditButton]="true"
      [showMoveButtons]="false"
      (editEvent)="editApp($event)"
    ></bh-app-tile>

    <!-- Add App -->
    <a
      href="javascript:void(0)"
      (click)="addApp()"
      class="cc-listing-item editor"
      *ngIf="
        !filterTerm &&
        !authUser.presentationMode &&
        (authUser.role === 'ADMIN' || authUser.role === 'SYS_ADMIN')
      "
    >
      <div class="cc-li-image clear-thumbnail">
        <ion-icon name="add-circle-outline"></ion-icon>
      </div>
      <div class="cc-li-name editor">
        Add App
        <span class="cc-li-description"></span>
      </div>
    </a>
  </div>
</div>
