/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { ContentContainer } from 'src/app/models/container';
import { ContainerType } from 'src/app/models/container-type';
import { ExplorerObject } from 'src/app/models/explorer-object';
import { SelectOption } from 'src/app/models/_core/select-option';


@Injectable({
  providedIn: 'root'
})
export class ContainerService {
  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { }


  goLiveSubject: BehaviorSubject<ContentContainer[]> = new BehaviorSubject([]);

  getAll(type?: string, parentId?: string, activeOnly?: boolean, tags?: string[]): Observable<ContentContainer[]> {
    const qryParentId = parentId ? `&parentId=${parentId}` : '';
    const qryActiveOnly = activeOnly ? `&activeOnly=${activeOnly}` : '';
    const qryTags = Array.isArray(tags) && tags.length > 0 ? `&tags=${tags.join(',')}` : '';
    const url = `${environment.apiUrl}/containers?type=${type}${qryParentId}${qryActiveOnly}${qryTags}&take=1000`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }

  getFeaturedApps(featuredType: 'HOME' | 'CLASS', parentId?: string): Observable<ContentContainer[]> {
    const qryParentId = parentId ? `&parentId=${parentId}` : '';
    const url = `${environment.apiUrl}/containers?type=APP&activeOnly=true&featured=${featuredType}${qryParentId}`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }


  getById(containerId: string): Observable<ContentContainer> {
    const url = `${environment.apiUrl}/containers/${containerId}`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }


  create(container: ContentContainer) {
    const url = `${environment.apiUrl}/containers`;
    // console.log('container service: container created', container);
    const body = this.prepareBody(container);
    return this.http.post(url, body).pipe(
      map((data: any) => data),
    );
  }

  update(container: ContentContainer) {
    console.log('container service', container);

    const url = `${environment.apiUrl}/containers/${container.id}`;
    // console.log('container service: container updated', container);
    const body = this.prepareBody(container);
    return this.http.patch(url, body).pipe(
      map((data: any) => data),
    );
  }

  prepareBody(container: ContentContainer): any {
    console.log('container prepare body', container);

    const body = Object.assign({}, container);
    // File Container Tags
    if (body && body.ContainerTags && body.ContainerTags.length > 0) {
      const tags: string[] = [];
      for (const ct of body.ContainerTags) {
        if (ct.isActive && ct.Tag) {
          tags.push(ct.Tag.name);
        }
      }
      body.tags = tags;
    }

    delete body.createdAt;
    delete body.createdBy;
    delete body.CreatedByUser;
    delete body.id;
    delete body.ContainerTags;
    delete body.updatedAt;
    delete body.updatedBy;
    delete body.UpdatedByUser;
    delete body.hide;
    delete body.ClassContainer
    delete body.SubCategoryContainer;
    delete body.AppContainer;
    delete body.childContent;
    delete body.parentName;
    delete body.viewSort;
    delete body.showSize;
    delete body.isFavorite;
    console.log('container: prepareBody: ', body);
    return body;
  }

  uploadFile(container: ContentContainer, rawFile: any) {
    const body = new FormData();
    body.append('file', rawFile);
    // console.log('**formData **', body.get('file'));
    const url = `${environment.apiUrl}/containers/${container.id}/upload`;
    // console.log('container service: container created', container);
    return this.http.post(url, body).pipe(
      map((data: any) => data),
    );
  }

  translateContainerTypeCode(type: ContainerType) {
    switch (type) {
      case 'APP':
        return 'Application';

      case 'CATEGORY':
        return 'Category';

      case 'CLASS':
        return 'Content Class';

      case 'TAB':
        return 'Tab';

      case 'GOLIVE':
        return 'Go-Live Page';
    }
  }

  setContainerTypeIcon(type: ContainerType): string {
    switch (type) {
      case 'CLASS':
        return 'albums-outline';

      case 'CATEGORY':
        return 'apps-outline';

      case 'APP':
        return 'browsers-outline';

      case 'TAB':
        return 'reorder-two-outline';

      default:
        return '';
    }
  }

  setContainerExploreObject(container: ContentContainer, sortIndex) {
    const explorerObject: ExplorerObject = {
      parentId: container.parentId,
      containerId: container.id,
      container: container,
      objectType: 'container',
      name: container.name,
      description: container.description,
      sort: sortIndex,
      type: container.type,
      isActive: container.isActive,
      children: [],
      isExpanded: false,
      icon: this.setContainerTypeIcon(container.type),
      imageUrl: container.imageUrl,
      url: container.url,
      startDateTime: container.startDateTime,
      endDateTime: container.endDateTime,
      isFavorite: false
    };

    return explorerObject;
  }


  getAudienceOptions(): SelectOption[] {
    return  [
      {
        label: 'All employees',
        value: 'All employees',
        active: 1
      },
      {
        label: 'Patients',
        value: 'Patients',
        active: 1
      },
      {
        label: 'Providers (Docs, PAs, NPs, etc.)',
        value: 'Providers',
        active: 1
      },
      {
        label: 'Clinical Teams',
        value: 'Clinical Teams',
        active: 1
      },
      {
        label: 'I&T',
        value: 'I&T',
        active: 1
      },
      {
        label: 'Contact Center',
        value: 'Contact Center',
        active: 1
      },
      {
        label: 'HIM',
        value: 'HIM',
        active: 1
      },
    ];
  }

  setGoLiveSubjects(goLiveState: ContentContainer[]) {
    this.goLiveSubject.next(goLiveState);
  }

  getGoLiveSubjects(): ContentContainer[] {
    console.log("Roster from subject", this.goLiveSubject.getValue());
    return this.goLiveSubject.getValue();
  }

}
