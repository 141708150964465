import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Icon } from 'src/app/models/icon-set';
import { IconSet } from '../../models/_data/icons';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'bh-icon-select',
  templateUrl: './bh-icon-select.component.html',
  styleUrls: ['./bh-icon-select.component.scss'],
})
export class BhIconSelectComponent  implements OnChanges {
  @Input() selectedIconName: string;
  @Input() label = 'Select icon';
  @Input() iconStyle: 'outline' | 'solid' | 'sharp' | 'all' = 'all';
  @Output() selectIconEvent = new EventEmitter();
  selectedIcon: Icon;
  iconSet = IconSet;
  icons: Icon[] = [];

  constructor(
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController
  ) { }

  ngOnChanges() {
    this.filterIconsByStyle();
    this.selectedIcon = this.icons.find(i => i.name === this.selectedIconName);
    // console.log('ngOnChanges > selectedIcon > ', this.selectedIconName, this.selectedIcon);
    for (const ic of this.icons) {
      ic.hide = false;
    }
  }

  ionViewWillEnter() {
  }

  filterIconsByStyle() {
    if (this.iconStyle !== 'all') {
      if (this.iconStyle === 'solid') {
        this.icons = this.iconSet.icons.filter(i => i.name.indexOf('-outline') === -1 && i.name.indexOf('-sharp') === -1)
      } else {
        this.icons = this.iconSet.icons.filter(i => i.name.indexOf('-' + this.iconStyle) > -1)
      }
    } else {
      this.icons = this.iconSet.icons;
    }
  }

  selectIcon(icon: Icon) {
    // console.log('selectIcon', icon);
    this.analytics.clickEvent('icon-select: selected', icon.name);
    this.selectedIcon = icon;
    this.selectIconEvent.emit(icon);
    this.dismiss();
  }

  search(ev) {
    const qry = ev.detail.value.toLowerCase();
    // console.log('icon-search', ev);
    for (const ic of this.icons) {
      const name = ic.name.toLowerCase();
      ic.hide = (name.indexOf(qry) === -1);
    }
  }

  dismiss() {
    this.analytics.clickEvent('icon-select: dismissed', '');
    this.modalCtrl.dismiss();
  }

}
