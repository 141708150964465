import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { UserMenuPopoverPageModule } from './pages/_core/user-menu-popover/user-menu-popover.module';
import { VerlockerPageModule } from './pages/_core/verlocker/verlocker.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { InterceptorService } from './services/_core/interceptor/interceptor.service';
import { BhAnalyticsRouteGuardService } from './services/_core/bh-analytics-route-guard/bh-analytics-route-guard.service';
import { LoginRouteGuardService } from './services/_core/login-route-guard/login-route-guard.service';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ManageContainerEditorPageModule } from './pages/manage-container-editor/manage-container-editor.module';
import { ManageContainerListPageModule } from './pages/manage-container-list/manage-container-list.module';
import { QuillConfigModule } from 'ngx-quill/config';

@NgModule({
  declarations: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md'
    }
    ),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatDialogModule,
    MatMomentDateModule,
    BrowserAnimationsModule,
    VerlockerPageModule,
    UserMenuPopoverPageModule,
    IonicStorageModule.forRoot(),
    QuillConfigModule.forRoot({
      modules: {
        // table: true,
        toolbar: [
          [{ 'header': [2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'align': [] }],
          ['clean'],                                         // remove formatting button
          ['link']                         // link and image, video
        ]
      },
      customOptions: [{
        import: 'formats/font',
        whitelist: ['Open Sans']
      }]
    }),
    ManageContainerEditorPageModule,
    ManageContainerListPageModule,

  ],
  providers: [
    LoginRouteGuardService,
    BhAnalyticsRouteGuardService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
