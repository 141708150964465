<div class="spinner-container">
  <ion-spinner
    *ngIf="type === 'spinner'"
    [color]="color"
    name="crescent"
  ></ion-spinner>
  <p
    *ngIf="message"
    class="text-align-center margin-top-24 font-weight-bold font-color-tertiary"
  >
    {{ message }}
  </p>
  <ion-list lines="full" *ngIf="type === 'skeleton'">
    <ion-item *ngFor="let item of skeletonArray">
      <!-- <ion-skeleton-text
        animated
        style="width: 27px; height: 27px"
        slot="start"
      ></ion-skeleton-text> -->
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </h3>
        <!-- <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p> -->
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-list>

  <div class="skeleton-boxes" *ngIf="type === 'skeleton-boxes'">
    <ion-skeleton-text animated class="skeleton-box" *ngFor="let item of skeletonArray"></ion-skeleton-text>
  </div>

  <div class="skeleton-tiles" *ngIf="type === 'skeleton-tiles'">
    <ion-skeleton-text animated class="skeleton-tile" *ngFor="let item of skeletonArray"></ion-skeleton-text>
  </div>

</div>
