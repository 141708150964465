<ion-header class="modal-header">
  <ion-toolbar>
    <ion-title>Content Explorer</ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" (click)="dismiss()" size="small"
        ><ion-icon name="close-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-segment
    [(ngModel)]="activeSegment"
    (ionChange)="filterByContainerType($event)"
    *ngIf="scope === 'global'"
  >
    <ion-segment-button [value]="seg.id" *ngFor="let seg of segments">
      <div class="inline-segment-label">
        <ion-icon [name]="seg.icon"></ion-icon>
        <span class="desktop-only">{{ seg.label }}</span>
      </div>
    </ion-segment-button>
  </ion-segment>
</ion-header>

<ion-content class="ion-padding">
  <div class="ion-content-inner">
    <h2>{{ segmentTitle }}</h2>
    <bh-spinner
      type="skeleton"
      [skeletonItemCount]="3"
      *ngIf="isLoading"
    ></bh-spinner>
    <div class="container-listing" *ngIf="!isLoading">
      <ion-searchbar
        mode="ios"
        placeholder="Search"
        (ionInput)="search($event)"
        [debounce]="200"
        *ngIf="activeSegment !== 'all' && activeSegment !== 'featured'"
      ></ion-searchbar>

      <ng-container *ngIf="activeSegment !== 'all'">
        <!-- Content View -->
        <ng-container *ngIf="activeSegment === 'content'">
          <div class="form-group-box">
            <bh-editor-content-listing
              [searchTerm]="searchTerm"
            ></bh-editor-content-listing>
          </div>
        </ng-container>

        <!-- Featured disclaimer -->
        <ng-container *ngIf="activeSegment === 'featured'">
          <p>
            The first 3 featured apps are displayed at the top of the Home page.
            Subsequent featured apps are hidden.
          </p>

          <div class="featured-item-placement"></div>
          <div class="featured-item-placement-text">
            Please note: The second featured app is displayed most prominently
            front and center. See placement diagram above.
          </div>
        </ng-container>

        <div
          class="list-message"
          [ngClass]="{'show': unsavedReorder, 'saved': showSavedMessage}"
        >
          <div *ngIf="!showSavedMessage && !showResetMessage">
            Save order of featured apps?
            <div class="margin-top-8">
              <ion-button
                fill="outline"
                size="small"
                color="primary"
                (click)="saveOrder();"
                >Save order</ion-button
              >
              <ion-button
                fill="clear"
                size="small"
                color="primary"
                (click)="resetOrder();"
                >Reset order</ion-button
              >
            </div>
          </div>
          <div class="message-wrapper" *ngIf="showResetMessage">
            <ion-icon name="arrow-undo-outline"></ion-icon>
            Featured app order reset.
          </div>
          <div class="message-wrapper" *ngIf="showSavedMessage">
            <ion-icon name="checkmark-circle"></ion-icon>
            Featured app order saved!
          </div>
        </div>

        <!-- Non-Content View -->
        <ng-container *ngIf="activeSegment !== 'content'">
          <div class="form-group-box">
            <ion-reorder-group
              [disabled]="activeSegment !== 'featured'"
              (ionItemReorder)="doReorder($event)"
            >
              <ng-container *ngFor="let c of containers; let i = index">
                <ion-item
                  button
                  (click)="edit(c, $event)"
                  [ngClass]="{'opacity-30': activeSegment ==='featured' && i > 2}"
                >
                  <ion-icon
                    name="albums-outline"
                    color="primary"
                    *ngIf="c.type === 'CLASS'"
                  ></ion-icon>
                  <ion-icon
                    name="apps-outline"
                    color="primary"
                    *ngIf="c.type === 'CATEGORY'"
                  ></ion-icon>
                  <ion-icon
                    name="browsers-outline"
                    color="primary"
                    *ngIf="c.type === 'APP'"
                  ></ion-icon>
                  <ion-icon
                    name="rocket-outline"
                    color="primary"
                    *ngIf="c.type === 'GOLIVE'"
                  ></ion-icon>
                  <ion-icon
                    name="reorder-three-outline"
                    color="primary"
                    *ngIf="c.type === 'TAB'"
                  ></ion-icon>
                  <ion-text>
                    <ion-label>
                      <strong>{{ c.name }}</strong>
                      <div class="details" *ngIf="c.type === 'TAB'">
                        {{ c?.AppContainer?.name }}
                      </div>
                      <div class="details" *ngIf="c.type === 'APP'">
                        {{ c?.SubCategoryContainer?.name }}
                      </div>
                      <div class="details" *ngIf="c.type === 'CATEGORY'">
                        {{ c?.ClassContainer?.name }}
                      </div>
                    </ion-label>
                  </ion-text>
                  <ion-badge color="danger" *ngIf="!c.isActive"
                    >Inactive</ion-badge
                  >
                  <div class="content-edit">
                    <a href="javascript:void(0)" (click)="edit(c, $event)">
                      <ion-icon name="pencil-outline"></ion-icon>
                      Edit
                    </a>
                    <a
                      href="javascript:void(0)"
                      (click)="view(c, null, null, $event)"
                    >
                      <ion-icon name="eye-outline"></ion-icon>
                      View
                    </a>
                  </div>
                  <ion-reorder
                    slot="end"
                    (click)="stopPropagation($event)"
                    *ngIf="activeSegment === 'featured'"
                  ></ion-reorder>
                </ion-item>

                <!-- <a
                href="javascript:void(0)"
                class="cl-item"
                button
                *ngIf="!c.hide"
                [ngClass]="c.type"
                (click)="edit(c, $event)"
              >
                <div class="cl-item-inner">
                  <ion-icon
                    name="albums-outline"
                    *ngIf="c.type === 'CLASS'"
                  ></ion-icon>
                  <ion-icon
                    name="apps-outline"
                    *ngIf="c.type === 'CATEGORY'"
                  ></ion-icon>
                  <ion-icon
                    name="browsers-outline"
                    *ngIf="c.type === 'APP'"
                  ></ion-icon>
                  <ion-icon
                    name="rocket-outline"
                    *ngIf="c.type === 'GOLIVE'"
                  ></ion-icon>
                  <ion-icon
                    name="reorder-two-outline"
                    *ngIf="c.type === 'TAB'"
                  ></ion-icon>
                  <ion-label>
                    {{ c.name }}
                    <div class="details" *ngIf="c.type === 'TAB'">
                      {{ c?.AppContainer?.name }}
                    </div>
                    <div class="details" *ngIf="c.type === 'APP'">
                      {{ c?.SubCategoryContainer?.name }}
                    </div>
                    <div class="details" *ngIf="c.type === 'CATEGORY'">
                      {{ c?.ClassContainer?.name }}
                    </div>
                  </ion-label>
                  <ion-badge color="danger" *ngIf="!c.isActive"
                    >Inactive</ion-badge
                  >
                  <div class="content-edit">
                    <a href="javascript:void(0)" (click)="edit(c, $event)">
                      <ion-icon name="pencil-outline"></ion-icon>
                      Edit
                    </a>
                    <a
                      href="javascript:void(0)"
                      (click)="view(c, null, null, $event)"
                    >
                      <ion-icon name="eye-outline"></ion-icon>
                      View
                    </a>
                  </div>
                </div>
              </a> -->
              </ng-container>
            </ion-reorder-group>
            <p *ngIf="containers?.length === 0">
              {{ modeLabel }} will display here.
            </p>
          </div>
        </ng-container>
      </ng-container>

      <!-- All view -->
      <ng-container *ngIf="activeSegment === 'all'">
        <div class="form-group-box">
          <!-- Classes -->
          <ng-container *ngFor="let cls of explorerObjects">
            <a
              href="javascript:void(0)"
              (click)="toggleExpand(cls, $event)"
              class="cl-item"
              [ngClass]="cls.type"
            >
              <div class="cl-item-inner">
                <!-- Chevron -->
                <div class="chevron-placeholder">
                  <ion-icon
                    name="chevron-down-outline"
                    class="chevron"
                    *ngIf="cls.isExpanded && cls.children.length > 0"
                  ></ion-icon>
                  <ion-icon
                    name="chevron-forward-outline"
                    class="chevron"
                    *ngIf="!cls.isExpanded && cls.children.length > 0"
                  ></ion-icon>
                </div>

                <!-- Class icon -->
                <ion-icon [name]="cls.icon"></ion-icon>

                <ion-label> {{ cls.name }} </ion-label>
                <ion-badge color="danger" *ngIf="!cls.isActive"
                  >Inactive</ion-badge
                >
                <div class="content-edit">
                  <a
                    href="javascript:void(0)"
                    (click)="edit(cls.container, $event)"
                  >
                    <ion-icon name="pencil-outline"></ion-icon>
                    Edit
                  </a>
                  <a
                    href="javascript:void(0)"
                    (click)="view(cls.container, null, null, $event)"
                  >
                    <ion-icon name="eye-outline"></ion-icon>
                    View
                  </a>
                </div>
              </div>

              <!-- Categories -->
              <div class="container-listing" *ngIf="cls.isExpanded">
                <ng-container *ngFor="let cat of cls.children">
                  <a
                    href="javascript:void(0)"
                    (click)="toggleExpand(cat, $event)"
                    class="cl-item"
                    [ngClass]="cat.type"
                  >
                    <div class="cl-item-inner">
                      <!-- Chevron -->
                      <div class="chevron-placeholder">
                        <ion-icon
                          name="chevron-down-outline"
                          class="chevron"
                          *ngIf="cat.isExpanded && cat.children.length > 0"
                        ></ion-icon>
                        <ion-icon
                          name="chevron-forward-outline"
                          class="chevron"
                          *ngIf="!cat.isExpanded && cat.children.length > 0"
                        ></ion-icon>
                      </div>

                      <!-- Cat Icon -->
                      <ion-icon [name]="cat.icon"></ion-icon>

                      <ion-label> {{ cat.name }} </ion-label>
                      <ion-badge color="danger" *ngIf="!cat.isActive"
                        >Inactive</ion-badge
                      >
                      <div class="content-edit">
                        <a
                          href="javascript:void(0)"
                          (click)="edit(cat.container, $event)"
                        >
                          <ion-icon name="pencil-outline"></ion-icon>
                          Edit
                        </a>
                        <a
                          href="javascript:void(0)"
                          (click)="view(cat.container, null, null, $event)"
                        >
                          <ion-icon name="eye-outline"></ion-icon>
                          View
                        </a>
                      </div>
                    </div>

                    <!-- Apps -->
                    <div class="container-listing" *ngIf="cat.isExpanded">
                      <ng-container *ngFor="let app of cat.children">
                        <a
                          href="javascript:void(0)"
                          (click)="toggleExpand(app, $event)"
                          class="cl-item"
                          [ngClass]="app.type"
                        >
                          <div class="cl-item-inner">
                            <!-- Chevron -->
                            <div class="chevron-placeholder">
                              <ion-icon
                                name="chevron-down-outline"
                                class="chevron"
                                *ngIf="app.isExpanded && app.children.length > 0"
                              ></ion-icon>
                              <ion-icon
                                name="chevron-forward-outline"
                                class="chevron"
                                *ngIf="!app.isExpanded && app.children.length > 0"
                              ></ion-icon>
                            </div>
                            <!-- App icon -->
                            <ion-icon [name]="app.icon"></ion-icon>

                            <ion-label> {{ app.name }} </ion-label>
                            <ion-badge color="danger" *ngIf="!app.isActive"
                              >Inactive</ion-badge
                            >
                            <div class="content-edit">
                              <a
                                href="javascript:void(0)"
                                (click)="edit(app.container, $event)"
                              >
                                <ion-icon name="pencil-outline"></ion-icon>
                                Edit
                              </a>
                              <a
                                href="javascript:void(0)"
                                (click)="view(app.container, cls.container, cat.container, $event)"
                              >
                                <ion-icon name="eye-outline"></ion-icon>
                                View
                              </a>
                            </div>
                          </div>

                          <!-- Tabs -->
                          <div class="container-listing" *ngIf="app.isExpanded">
                            <ng-container *ngFor="let tab of app.children">
                              <a
                                href="javascript:void(0)"
                                (click)="toggleExpand(tab, $event)"
                                class="cl-item"
                                [ngClass]="tab.type"
                              >
                                <div class="cl-item-inner">
                                  <!-- Chevron -->
                                  <div class="chevron-placeholder">
                                    <ion-icon
                                      name="chevron-down-outline"
                                      class="chevron"
                                      *ngIf="tab.isExpanded && tab.children.length > 0"
                                    ></ion-icon>
                                    <ion-icon
                                      name="chevron-forward-outline"
                                      class="chevron"
                                      *ngIf="!tab.isExpanded && tab.children.length > 0"
                                    ></ion-icon>
                                  </div>
                                  <!-- Tab Icon -->
                                  <ion-icon [name]="tab.icon"></ion-icon>
                                  <ion-label> {{ tab.name }} </ion-label>
                                  <ion-badge
                                    color="danger"
                                    *ngIf="!tab.isActive"
                                    >Inactive</ion-badge
                                  >
                                  <div class="content-edit">
                                    <a
                                      href="javascript:void(0)"
                                      (click)="edit(tab.container, $event)"
                                    >
                                      <ion-icon
                                        name="pencil-outline"
                                      ></ion-icon>
                                      Edit
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      (click)="view(tab.container, cls.container, cat.container, $event)"
                                    >
                                      <ion-icon name="eye-outline"></ion-icon>
                                      View
                                    </a>
                                  </div>
                                </div>
                                <!-- Content -->
                                <div
                                  class="container-listing content"
                                  *ngIf="tab.isExpanded && tab.children.length > 0"
                                >
                                  <ng-container
                                    *ngFor="let con of tab.children"
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      class="cl-item"
                                      [ngClass]="con.type"
                                    >
                                      <div class="cl-item-inner">
                                        <!-- Content Icon -->
                                        <ion-icon
                                          [name]="con.icon"
                                          color="primary"
                                        ></ion-icon>

                                        <ion-label> {{ con.name }} </ion-label>
                                        <ion-badge
                                          color="danger"
                                          *ngIf="!con.isActive"
                                          >Inactive</ion-badge
                                        >
                                        <div class="content-edit">
                                          <a
                                            href="javascript:void(0)"
                                            (click)="editContent(con.content, $event)"
                                          >
                                            <ion-icon
                                              name="pencil-outline"
                                            ></ion-icon>
                                            Edit
                                          </a>
                                          <a
                                            href="javascript:void(0)"
                                            (click)="viewContent(con.content, $event)"
                                          >
                                            <ion-icon
                                              name="eye-outline"
                                            ></ion-icon>
                                            View
                                          </a>
                                        </div>
                                      </div>
                                    </a>
                                  </ng-container>
                                </div>
                              </a>
                            </ng-container>
                          </div>
                        </a>
                      </ng-container>
                    </div>
                  </a>
                </ng-container>
              </div>
            </a>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ion-content>
