import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BhAppLogoComponentModule } from '../_core/bh-app-logo/bh-app-logo.component.module';
import { BhUserBadgeComponentModule } from '../_core/bh-user-badge/bh-user-badge.component.module';
import { BhSpinnerComponentModule } from '../_core/bh-spinner/bh-spinner.component.module';
import { BhCategoryContainerComponent } from './bh-category-container.component';
import { BhAppTileComponentModule } from '../bh-app-tile/bh-app-tile.component.module';

@NgModule({
  declarations: [
    BhCategoryContainerComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BhAppLogoComponentModule,
    BhUserBadgeComponentModule,
    BhSpinnerComponentModule,
    BhAppTileComponentModule
  ],
  exports: [
    BhCategoryContainerComponent
  ]
})
export class BhCategoryContainerComponentModule { }
