import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { environment } from 'src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

/**
 * ID: bh-app-logo
 * Name: BH App Logo
 * Description: Displays standardized app logo
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Added DEV badge to logo
 */
@Component({
  selector: 'bh-app-logo',
  templateUrl: './bh-app-logo.component.html',
  styleUrls: ['./bh-app-logo.component.scss'],
})
export class BhAppLogoComponent implements OnInit {
  env = environment;
  appName = this.env.appDescription;
  @Input() size = 'medium';
  @Input() responsive = false;
  @Input() color = null;
  @Input() transparentIcon = false;

  constructor(
    private notifications: NotificationsService
  ) { }

  devInfo() {
    this.notifications.showDevWarning();
  }

  ngOnInit() { }

}
