import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription, firstValueFrom } from 'rxjs';
import { Content } from 'src/app/models/content';
import { Tag } from 'src/app/models/tag';
import { TagType } from 'src/app/models/tag-type';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ContentService } from 'src/app/services/content/content.service';
import { TagService } from 'src/app/services/tags/tags.service';
import { ManageContentEditorPage } from '../manage-content-editor/manage-content-editor.page';
import { ContentContainer } from 'src/app/models/container';
import { ErrorHandlerService } from 'src/app/services/_core/error-handler/error-handler.service';

@Component({
  selector: 'app-manage-tag-editor',
  templateUrl: './manage-tag-editor.page.html',
  styleUrls: ['./manage-tag-editor.page.scss'],
})
export class ManageTagEditorPage implements OnInit, OnDestroy {
  @Input() editMode: 'new' | 'edit' = 'new';
  @Input() tagType: TagType;
  @Input() tagId: string;
  @Input() classContainerId: string;
  @Input() categoryContainerId: string;
  @Input() appContainerId: string;
  @Input() tabContainerId: string;
  @Input() parentContainerId: string;
  @Input() activeSegment: 'form' | 'content' = 'form';
  content: Content[] = [];
  modalTitle = '';
  tagTypeName = '';
  tag: Tag;
  initTagName = '';
  formReady = false;
  checkStatus: 'not-checked' | 'checking' | 'new' | 'exists' = 'not-checked';
  checkTimer = null;
  tagNameHelperText = '';

  form1: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    description: [null],
    isActive: [true],
  });
  subscriptions: Subscription[] = [];
  showErrorMessage = false;
  submitAttempted = false;
  validationMessages = {
    name: [{ type: 'required', message: 'Name is required.' }],
  };

  constructor(
    private formBuilder: FormBuilder,
    private tagService: TagService,
    private contentService: ContentService,
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private notifications: NotificationsService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit() {
    // console.log('manage-tag-editor: tagType', this.tagType)
    this.setEditMode();
    this.subscribeToControls();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
      s = null;
    });
  }

  setEditMode() {
    const typeName = this.tagService.translateTagTypeCode(this.tagType);
    // console.log('setEditMode', this.tagType, typeName, this.tagId);
    this.tagTypeName = typeName;
    if (this.editMode === 'edit' && this.tagId) {
      this.modalTitle = 'Edit ' + typeName;
      this.loadData();
    } else {
      this.modalTitle = 'New ' + typeName;
      this.tag = {};
    }
  }

  isNameValid() {
    return (
      (this.editMode === 'edit' && this.checkStatus !== 'new' && this.initTagName === this.form1.controls.name.value.toLowerCase()) ||
      (this.checkStatus === 'new')
    );
  }

  subscribeToControls() {
    if (this.subscriptions.length === 0) {
      this.subscriptions.push(
        this.form1.controls.name.valueChanges.subscribe(async val => {
          if (this.initTagName.toLowerCase() !== val.toLowerCase()) {
            this.checkForExistingTag(val);
          } else {
            this.checkStatus = 'not-checked';
            this.formReady = true;
          }
        })
      )
    }
  }

  async loadData() {
    try {
      const res = await firstValueFrom(this.tagService.getById(this.tagId));
      if (res) {
        this.tag = res;
        this.initTagName = !this.initTagName ? this.tag.name : this.initTagName;
        // console.log('Loading tag', this.tag);
        this.form1.controls.name.setValue(res.name);
        this.form1.controls.description.setValue(res.description);
        this.form1.controls.isActive.setValue(res.isActive);
        this.checkStatus = 'new';
        await this.loadContent();
      }
    } catch (err) {
      this.modalCtrl.dismiss();
      this.errorHandler.handleError(err, 'manage-tag-editor.loadData');
    }
  }

  async loadContent() {
    if (this.parentContainerId) {
      try {
        const res = await firstValueFrom(this.contentService.getAll(this.parentContainerId, false, [this.tag.name]));
        if (res && Array.isArray(res)) {
          this.content = res;
          // console.log('Loading content', this.content);
        }
      } catch (err) {
        this.errorHandler.handleError(err, 'bh-list-card.loadData');
      }
    } else {
      // console.log('manage-tag-editor.loadContent', this.parentContainerId);
    }
    return Promise.resolve(true);
  }

  async editContent(content: Content) {
    // console.log('editing', content);
    this.analytics.clickEvent('manage-tag-editor > Edit Content', content.name);
    const modal = await this.modalCtrl.create({
      component: ManageContentEditorPage,
      cssClass: 'wide-modal',
      componentProps: {
        editMode: 'edit',
        contentType: content.type,
        contentId: content.id,
        classContainerId: this.classContainerId,
        categoryContainerId: this.categoryContainerId,
        appContainerId: this.appContainerId,
        tabContainerId: this.tabContainerId,
        parentContainerId: this.parentContainerId,
      }
    });

    modal.onDidDismiss().then(data => {
      if (data && data.data && data.data.isSaved) {
        this.loadData();
      }
    });

    await modal.present();

  }

  async checkForExistingTag(name: string) {
    if (this.checkTimer) {
      clearTimeout(this.checkTimer);
      this.checkTimer = null;
    }
    if (name && !this.checkTimer) {
      this.checkTimer = setTimeout(async () => {
        this.formReady = false;
        this.checkStatus = 'checking';
        try {
          const res = await firstValueFrom(this.tagService.getByName(name.toLowerCase()));
          const tagsOfType = res && res.length > 0 ? res.filter(r => r.type === this.tagType) : [];
          if (tagsOfType.length > 0) {
            this.checkStatus = 'exists';
            this.formReady = false;
          } else {
            this.checkStatus = 'new';
            this.formReady = true;
          }
        } catch (err) {
          // this.modalCtrl.dismiss();
          this.errorHandler.handleError(err, 'manage-tag-editor.loadData');
        }
      }, 500);
    } else {
      this.checkStatus = 'not-checked';
    }

  }

  async save() {
    // console.log('saving: ', this.form1, this.tag, this.editMode);
    this.submitAttempted = true;
    if (this.form1.valid) {
      try {
        // Update container properties
        this.tag.type = this.tagType;
        const tagName = this.form1.controls.name.value;
        this.tag.name = this.tagType === 'SECTION' ? tagName : tagName.toLowerCase();
        this.tag.description = this.form1.controls.description.value;
        this.tag.isActive = this.form1.controls.isActive.value;
        this.tag.sort = 99999;

        console.log('tag-editor.save: tagName: ', this.tag);

        // Save container
        if (this.editMode === 'edit' && this.tag.id) {
          const res = await firstValueFrom(this.tagService.update(this.tag));
        } else {
          const res = await firstValueFrom(this.tagService.create(this.tag));
          this.tag.id = res.id;
        }
        this.analytics.clickEvent('manage-tag-editor: save', '');
        this.notifications.showToast('Saved successfully.');
        // this.modalCtrl.dismiss({ savedTag: this.tag });
        this.modalCtrl.dismiss({ isSaved: true });
      } catch (err) {
        this.errorHandler.handleError(err, 'manage-tag-editor.save');
      }
    }
  }

  dismiss() {
    this.analytics.clickEvent('manage-tag-editor: dismiss', '');
    this.modalCtrl.dismiss();
  }

}
