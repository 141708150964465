import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { ContentContainer } from 'src/app/models/container';
import { Content } from 'src/app/models/content';
import { ManageContainerEditorPage } from 'src/app/pages/manage-container-editor/manage-container-editor.page';
import { ManageContentEditorPage } from 'src/app/pages/manage-content-editor/manage-content-editor.page';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { ErrorHandlerService } from 'src/app/services/_core/error-handler/error-handler.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { ContentViewHandlerService } from 'src/app/services/content-view-handler/content-view-handler.service';
import { ContentService } from 'src/app/services/content/content.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Component({
  selector: 'bh-editor-content-listing',
  templateUrl: './bh-editor-content-listing.component.html',
  styleUrls: ['./bh-editor-content-listing.component.scss'],
})
export class BhEditorContentListingComponent implements OnInit, OnChanges {
  @Input() appContainer: ContentContainer;
  @Input() tabContainer: ContentContainer;
  @Input() heading = '';
  @Input() searchTerm = '';
  @Input() showTabs = false;
  @Input() reloadData = false;
  @Output() contentLoaded = new EventEmitter();

  initTabContainers: ContentContainer[] = [];
  tabContainers: ContentContainer[] = [];
  initContent: Content[] = [];
  content: Content[] = [];
  isLoaded = false;

  constructor(
    private contentService: ContentService,
    private contentViewer: ContentViewHandlerService,
    private containerService: ContainerService,
    private notifications: NotificationsService,
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController,
    private errorHandler: ErrorHandlerService
  ) { }

  async ngOnInit() {
    await this.loadData();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if ('reloadData' in changes && this.isLoaded) {
      await this.loadData();
    }

    if ('searchTerm' in changes) {
      this.search(this.searchTerm);
    }
  }

  async loadData(): Promise<boolean> {
    try {
      // console.log('**** bh-editor-content-listing.loadData');
      this.isLoaded = false;
      this.clearContent();
      await this.loadContent();
      if (this.showTabs) {
        await this.loadTabs();
      }
      this.isLoaded = true;
      this.contentLoaded.emit(true);
    } catch (err) {
      this.errorHandler.handleError(err, 'loadData');
    }
    return Promise.resolve(true);
  }

  clearContent() {
    this.content = [];
    this.initContent = [];
    this.initTabContainers = [];
    this.tabContainers = [];
  }

  async loadContent() {
    try {
      let res;
      if (this.appContainer && this.appContainer.id) {
        res = await firstValueFrom(this.contentService.getAllByAppId(this.appContainer.id, false));
      } else {
        res = await firstValueFrom(this.contentService.getAll(null, false));
      }
      if (res && Array.isArray(res) && res.length > 0) {
        const content = res.filter(r => r.type !== 'EVENT');
        // Filter Application Data
        this.initContent = this.appContainer ? content.filter(r =>
          r.appContainerId === this.appContainer.id
        ) : content;
        // Then Filter Tab Data
        this.initContent = this.tabContainer ?
          this.initContent.filter(r => r.tabContainerId === this.tabContainer.id) :
          this.initContent;
        this.sortContent();
        this.content = Object.assign([], this.initContent);
        // console.log('**** loadContent', this.initContent);
      }
      return Promise.resolve(true);
      // this.classes = this.testClasses;
    } catch (err) {
      this.errorHandler.handleError(err, 'loadContent');
      return Promise.reject();
    }
  }

  async loadTabs() {
    try {
      const res = await firstValueFrom(this.containerService.getAll('TAB', this.appContainer.id, false));
      if (res && Array.isArray(res) && res.length > 0) {
        const containers = res;
        this.initTabContainers = this.initTabContainers.concat(containers);
        // console.log('loadTabs', this.initTabContainers);
        this.initTabContainers.sort((a, b) => { return a.sort - b.sort; });
        for (const c of this.initTabContainers) {
          c.childContent = this.content.filter(ch => ch.tabContainerId === c.id);
        }
        this.tabContainers = Object.assign([], this.initTabContainers);
      }
      return Promise.resolve(true);
      // this.classes = this.testClasses;
    } catch (err) {
      this.errorHandler.handleError(err, 'loadTabs');
      return Promise.reject();
    }
  }

  sortContent() {
    this.initContent.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });
  }

  async editContent(content: Content, ev) {
    ev.stopPropagation();
    console.log('editing', content);
    this.analytics.clickEvent('Manage-Containers > Edit', content.name);
    const modal = await this.modalCtrl.create({
      component: ManageContentEditorPage,
      cssClass: 'wide-modal',
      componentProps: {
        editMode: 'edit',
        classContainerId: content.ClassContainer.id,
        categoryContainerId: content.SubCategoryContainer.id,
        appContainerId: content.AppContainer.id,
        tabContainerId: content.TabContainer ? content.TabContainer.id : undefined,
        parentContainerId: content.TabContainer ? content.TabContainer.id : undefined,
        contentType: content.type,
        contentId: content.id,
      }
    });

    modal.onDidDismiss().then((val) => {
      this.loadData();
    });

    await modal.present();
  }

  viewContent(c: Content, ev) {
    ev.stopPropagation();
    this.analytics.clickEvent('manage-container-list: content', c.name);
    this.contentViewer.openContent(c);
    // switch (c.type) {
    //   case 'LINK':
    //     this.openLink(c.icon || c.url);
    //     break;

    //   case 'GOLIVE':
    //     this.navService.navigateForward('/tabs/golive/' + c.id);
    //     break;

    //   default:
    //     if (c.ClassContainer && c.SubCategoryContainer && c.AppContainer) {
    //       this.navService.navigateForward('/tabs/explore/' + c.ClassContainer.id + '/' + c.SubCategoryContainer.id + '/' + c.AppContainer.id + '/' + c.id);
    //     } else if (c.ClassContainer && c.SubCategoryContainer && c.AppContainer) {
    //       this.navService.navigateForward('/tabs/explore/' + c.ClassContainer.id + '/' + c.SubCategoryContainer.id + '/' + c.AppContainer.id + '/' + c.id);
    //     } else {
    //       this.navService.navigateForward('/tabs/content/' + c.id);
    //     }
    // }
  }

  async editTab(container: ContentContainer, ev) {
    ev.stopPropagation();
    // console.log('editing', container);
    this.analytics.clickEvent('Manage-Containers > Edit', container.name);
    const modal = await this.modalCtrl.create({
      component: ManageContainerEditorPage,
      cssClass: 'wide-modal',
      componentProps: {
        editMode: 'edit',
        containerType: container.type,
        containerId: container.id
      }
    });

    modal.onDidDismiss().then((val) => {
      this.loadData();
    });

    await modal.present();
  }


  // openLink(url) {
  //   if (this.platform.is('mobile')) {
  //     window.open(url, '_system');
  //   } else {
  //     window.open(url);
  //   }
  // }

  search(ev) {
    this.searchTerm = (ev.detail) ? ev.detail.value : this.searchTerm;
    const qry = this.searchTerm.toLowerCase();
    if (qry) {
      for (const c of this.content) {
        c.hide = c.name.toLowerCase().indexOf(qry) === -1;
      }
    } else {
      for (const c of this.content) {
        c.hide = false;
      }
    }
    // console.log('search', this.searchTerm);
  }


}
