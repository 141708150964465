import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ManageContainerEditorPageRoutingModule } from './manage-container-editor-routing.module';

import { ManageContainerEditorPage } from './manage-container-editor.page';
import { BhInputComponentModule } from 'src/app/components/_core/bh-input/bh-input.component.module';
import { BhFileUploaderComponentModule } from 'src/app/components/_core/bh-file-uploader/bh-file-uploader.component.module';
import { BhIconSelectComponentModule } from 'src/app/components/bh-icon-select/bh-icon-select.component.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ManageContainerEditorPageRoutingModule,
    BhInputComponentModule,
    BhFileUploaderComponentModule,
    BhIconSelectComponentModule,
    BhFileUploaderComponentModule,
    PipesModule
  ],
  declarations: [ManageContainerEditorPage]
})
export class ManageContainerEditorPageModule {}
