import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { Tag } from 'src/app/models/tag';
import { TagType } from 'src/app/models/tag-type';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { TagService } from 'src/app/services/tags/tags.service';
import { ManageTagEditorPage } from '../manage-tag-editor/manage-tag-editor.page';
import { ContentContainer } from 'src/app/models/container';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { ErrorHandlerService } from 'src/app/services/_core/error-handler/error-handler.service';

@Component({
  selector: 'app-manage-tag-list',
  templateUrl: './manage-tag-list.page.html',
  styleUrls: ['./manage-tag-list.page.scss'],
})
export class ManageTagListPage implements OnInit {
  @Input() tagType: TagType;
  @Input() classContainerId: string;
  @Input() categoryContainerId: string;
  @Input() appContainerId: string;
  @Input() tabContainerId: string;
  @Input() parentContainerId: string;
  @Input() excludedTags: Tag[];
  @Input() selectMode: 'apply' | 'edit' = 'apply';
  initTags: Tag[] = [];
  tags: Tag[] = [];
  modeLabel = 'Tag';
  searchTerm = '';
  activeSegment: 'all' | 'tag' | 'section' = 'all';
  isLoading = true;
  authUser: User;

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private tagService: TagService,
    private notifications: NotificationsService,
    private authService: AuthService,
    private errorHandler: ErrorHandlerService
  ) { }

  async ngOnInit() {
    this.authUser = this.authService.getAuthUser();
    this.setModeLabel();
    this.setDefaultSegment();
    await this.loadData();
    // console.log('ngOnInit: tags: ', this.tags);
  }

  setDefaultSegment() {
    switch (this.tagType) {
      case 'SECTION':
        this.activeSegment = 'section';
        break;

      case 'TAG':
        this.activeSegment = 'tag';
        break;

      default:
        this.activeSegment = 'all';
    }
  }

  setModeLabel() {
    this.modeLabel = this.tagType.toLowerCase();
  }

  async loadData(): Promise<boolean> {
    this.isLoading = true;
    this.initTags = [];
    this.tags = [];
    try {
      const res = await firstValueFrom(this.tagService.getAll(false));
      if (res && Array.isArray(res) && res.length > 0) {
        const tags = res;
        this.initTags = this.initTags.concat(tags);
        this.tags = Object.assign([], this.initTags);
        // console.log('loadData', this.initTags)
        this.sortTags();
        this.filterByType(this.activeSegment);
        this.isLoading = false;
      }
    } catch (err) {
      this.errorHandler.handleError(err, 'loadData');
      return Promise.reject();
    }
    return Promise.resolve(true);
  }

  filterByType(ev) {
    // console.log('filterByType: ev', ev);
    const activeSegment: string = ev.detail ? ev.detail.value : ev;
    if (activeSegment === 'all') {
      this.tags = Object.assign([], this.initTags);
    } else {
      const tagType = activeSegment.toUpperCase();
      this.tags = this.initTags.filter(ic => ic.type === tagType);
      this.search(this.searchTerm);
    }
  }

  async addTag() {
    this.analytics.clickEvent('manage-tag-list > Add tag/section', '');
    const modal = await this.modalCtrl.create({
      component: ManageTagEditorPage,
      cssClass: 'wide-modal',
      componentProps: {
        editMode: 'new',
        tagType: this.tagType,
        // classContainerId: this.classContainerId,
        // categoryContainerId: this.categoryContainerId,
        // appContainerId: this.appContainerId,
        // tabContainerId: this.tabContainerId,
        // parentContainerId: this.parentContainerId,
      }
    });

    modal.onDidDismiss().then(async (data) => {
      // console.log('New Section dismissed', data);
      if (data && data.data && data.data.isSaved) {
        this.loadData();
      }
    });

    await modal.present();

  }

  async select(tag: Tag) {
    this.analytics.clickEvent('manage-tag-list > Select', tag.name);
    if (this.selectMode === 'apply') {
      this.modalCtrl.dismiss({isSaved: true, tag});
    } else {
      this.edit(tag, null);
    }
    // this.selectEvent.emit(tag);
  }

  async edit(tag: Tag, ev) {
    if (ev) {
      ev.stopPropagation();
    }
    // console.log('editing', tag);
    this.analytics.clickEvent('manage-tag-list > Edit', tag.name);
    const modal = await this.modalCtrl.create({
      component: ManageTagEditorPage,
      cssClass: 'wide-modal',
      componentProps: {
        editMode: 'edit',
        tagType: tag.type,
        tagId: tag.id,
        classContainerId: this.classContainerId,
        categoryContainerId: this.categoryContainerId,
        appContainerId: this.appContainerId,
        tabContainerId: this.tabContainerId,
        parentContainerId: this.parentContainerId,
      }
    });

    modal.onDidDismiss().then(data => {
      if (data && data.data && data.data.isSaved) {
        this.loadData();
      }
    });

    await modal.present();
  }

  sortTags() {
    const sortedTags: Tag[] = this.initTags;
    sortedTags.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    this.initTags = sortedTags;
    this.tags = Object.assign([], sortedTags);
  }

  search(ev) {
    this.searchTerm = (ev.detail) ? ev.detail.value : this.searchTerm;
    const qry = this.searchTerm.toLowerCase();
    if (qry) {
      for (const t of this.tags) {
        t.hide = t.name.toLowerCase().indexOf(qry) === -1;
      }
    } else {
      for (const t of this.tags) {
        t.hide = false;
      }
    }
    // console.log('search', this.searchTerm);
  }

  dismiss() {
    this.analytics.clickEvent('manage-tag-list: dismiss', '');
    this.modalCtrl.dismiss();
  }

}
