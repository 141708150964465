import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BhAnalyticsRouteGuardService } from './services/_core/bh-analytics-route-guard/bh-analytics-route-guard.service';
import { LoginRouteGuardService } from './services/_core/login-route-guard/login-route-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    pathMatch: 'full',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'Home', roles: [] },
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
      }
    ]
  },
  {
    path: 'help-forgot-pwd',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () =>
      import('./pages/_core/help-forgot-pwd/help-forgot-pwd.module').then(m => m.HelpForgotPwdPageModule)
  },
  {
    path: 'share',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/share/share.module').then(m => m.SharePageModule)
  },
  {
    path: 'help',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'help-no-pin',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/help-no-pin/help-no-pin.module').then(m => m.HelpNoPinPageModule)
  },
  {
    path: 'check-for-updates',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'Check for Updates', roles: [] },
    loadChildren: () => import('./pages/_core/check-for-updates/check-for-updates.module').then(m => m.CheckForUpdatesPageModule)
  },
  {
    path: 'about',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'my-account',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    data: { pageName: 'My Account', roles: ['USER', 'ADMIN', 'SYS_ADMIN'] },
    loadChildren: () => import('./pages/_core/my-account/my-account.module').then(m => m.MyAccountPageModule)
  },
  {
    path: 'bh-input-text-editor',
    loadChildren: () => import('./components/_core/bh-input-text-editor/bh-input-text-editor.module')
      .then(m => m.BhInputTextEditorPageModule)
  },
  {
    path: 'feedback',
    data: { pageName: 'Feedback', roles: [] },
    loadChildren: () => import('./pages/_core/feedback/feedback.module').then(m => m.FeedbackPageModule)
  },
  {
    path: 'notifications',
    data: { pageName: 'Notifications', roles: ['USER', 'ADMIN', 'SYS_ADMIN'] },
    loadChildren: () => import('./pages/_core/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then( m => m.EventsPageModule)
  },
  {
    path: 'content',
    loadChildren: () => import('./pages/content/content.module').then( m => m.ContentPageModule)
  },
  {
    path: 'manage-container-editor',
    loadChildren: () => import('./pages/manage-container-editor/manage-container-editor.module').then( m => m.ManageContainerEditorPageModule)
  },
  {
    path: 'manage-container-list',
    loadChildren: () => import('./pages/manage-container-list/manage-container-list.module').then( m => m.ManageContainerListPageModule)
  },
  {
    path: 'application',
    loadChildren: () => import('./pages/application/application.module').then( m => m.ApplicationPageModule)
  },
  {
    path: 'application-share',
    loadChildren: () => import('./pages/application-share/application-share.module').then( m => m.ApplicationSharePageModule)
  },
  {
    path: 'manage-tag-list',
    loadChildren: () => import('./pages/manage-tag-list/manage-tag-list.module').then( m => m.ManageTagListPageModule)
  },
  {
    path: 'manage-tag-editor',
    loadChildren: () => import('./pages/manage-tag-editor/manage-tag-editor.module').then( m => m.ManageTagEditorPageModule)
  },
  {
    path: 'manage-content-editor',
    loadChildren: () => import('./pages/manage-content-editor/manage-content-editor.module').then( m => m.ManageContentEditorPageModule)
  },
  {
    path: 'manage-content-list',
    loadChildren: () => import('./pages/manage-content-list/manage-content-list.module').then( m => m.ManageContentListPageModule)
  },
  {
    path: 'announcement',
    loadChildren: () => import('./pages/announcement/announcement.module').then( m => m.AnnouncementPageModule)
  },
  {
    path: 'manage-user-list',
    loadChildren: () => import('./pages/manage-user-list/manage-user-list.module').then( m => m.ManageUserListPageModule)
  },
  {
    path: 'manage-user-editor',
    loadChildren: () => import('./pages/manage-user-editor/manage-user-editor.module').then( m => m.ManageUserEditorPageModule)
  },
  {
    path: 'tango-player',
    loadChildren: () => import('./pages/tango-player/tango-player.module').then( m => m.TangoPlayerPageModule)
  },
  {
    path: 'manage-events-importer',
    loadChildren: () => import('./pages/manage-events-importer/manage-events-importer.module').then( m => m.ManageEventsImporterPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'favorites',
    loadChildren: () => import('./pages/favorites/favorites.module').then( m => m.FavoritesPageModule)
  },
  {
    path: 'error-modal',
    loadChildren: () => import('./pages/_core/error-modal/error-modal.module').then( m => m.ErrorModalPageModule)
  },
  {
    path: 'content-feedback-modal',
    loadChildren: () => import('./pages/content-feedback-modal/content-feedback-modal.module').then( m => m.ContentFeedbackModalPageModule)
  },
  {
    path: 'content-hero-mobile-menu',
    loadChildren: () => import('./components/bh-content-hero/content-hero-mobile-menu/content-hero-mobile-menu.module').then( m => m.ContentHeroMobileMenuPageModule)
  },
  {
    path: 'redirect',
    loadChildren: () => import('./pages/_core/redirect/redirect.module').then( m => m.RedirectPageModule)
  },
  {
    path: 'content-viewer-modal',
    loadChildren: () => import('./pages/content-viewer-modal/content-viewer-modal.module').then( m => m.ContentViewerModalPageModule)
  },
  {
    path: 'content-viewer-modal-ellipsis-menu',
    loadChildren: () => import('./pages/content-viewer-modal-ellipsis-menu/content-viewer-modal-ellipsis-menu.module').then( m => m.ContentViewerModalEllipsisMenuPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/_core/error/error.module').then( m => m.ErrorPageModule)
  },
  {
    path: 'error/:errType',
    loadChildren: () => import('./pages/_core/error/error.module').then( m => m.ErrorPageModule)
  },
  // {
  //   path: '**',
  //   redirectTo: 'error/404'
  // }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
