import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { Content } from 'src/app/models/content';
import { User } from 'src/app/models/user';
import { UserActivity } from 'src/app/models/user-activity';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { UserActivityService } from 'src/app/services/user-activity/user-activity.service';
import { ContentFeedbackModalPage } from '../content-feedback-modal/content-feedback-modal.page';
import { ContentViewerModalEllipsisMenuPage } from '../content-viewer-modal-ellipsis-menu/content-viewer-modal-ellipsis-menu.page';
import { ErrorHandlerService } from 'src/app/services/_core/error-handler/error-handler.service';

@Component({
  selector: 'app-content-viewer-modal',
  templateUrl: './content-viewer-modal.page.html',
  styleUrls: ['./content-viewer-modal.page.scss'],
})
export class ContentViewerModalPage implements OnInit {
  @Input() content: Content
  url: string;
  isHelpful = false;
  authUser: User;
  userActivity: UserActivity;

  constructor(
    private modalCtrl: ModalController,
    private popCtrl: PopoverController,
    private analytics: BhAnalyticsService,
    private activityService: UserActivityService,
    private authService: AuthService,
    private notifications: NotificationsService,
    private errorHandler: ErrorHandlerService
  ) {
  }

  ngOnInit() {
    this.authUser = this.authService.getAuthUser();
    this.setUrlQueryString('content', this.content.id);
    this.loadActivityRecord();
    this.loadContent();
  }

  loadContent() {
    this.analytics.screenView('content-viewer-modal', this.content.name);
    switch(this.content.type) {
      case 'TANGO':
        this.loadTango();
        break;

      default:
        //console.log('content', this.content);
        this.url = this.content.icon || this.content.url; // PDF documents are stored in the icon field
        break;
    }
  }

  async loadActivityRecord() {
    try {
      // Try loading record
      const res = await firstValueFrom(this.activityService.getByUserAndContentId(this.authUser, this.content));
      if (res && Array.isArray(res) && res.length > 0) {
        // console.log('loadActivityRecord', res);
        this.userActivity = res[res.length - 1];
      } else {
        // Record doesn't exist, create record
        const newActivity: UserActivity = {
          userId: this.authUser.userId,
          userName: this.authUser.userName,
          contentId: this.content.id,
          isFavorite: false,
          status: 'STARTED',
          isActive: true
        };
        const createRes = await firstValueFrom(this.activityService.create(this.authUser, newActivity));
        if (createRes) {
          // console.log('createdRess', createRes);
          this.userActivity = createRes;
        }
      }
    } catch (err) {
      this.errorHandler.handleError(err, 'loadActivityRecord');
    }
  }

  loadTango() {
    const tangoId = this.content.videoId;
    this.url = `https://app.tango.us/app/embed/${tangoId}?skipCover=true&defaultListView=true&skipBranding=false`;
    this.setUrlQueryString('content', this.content.id);
  }

  async shareFeedback() {
    const modal = await this.modalCtrl.create({
      component: ContentFeedbackModalPage,
      componentProps: {
        content: this.content
      }
    });
    modal.onDidDismiss().then((data) => {
    });
    await modal.present();

  }

  setUrlQueryString(property, value) {
    // Get the current page's protocol (http or https)
    const protocol: string = window.location.protocol;

    // Get the current page's domain (including subdomains)
    const domain: string = window.location.host;

    // Get the current page's path
    const path: string = window.location.pathname;

    // Build url
    const url = property ? `${protocol}//${domain}${path}?${property}=${value}` : `${protocol}//${domain}${path}`;
    console.log('new url', url);

    // Push as current url
    window.history.pushState({}, "WorkTips - " + value, url);
  }

  dismiss() {
    this.analytics.clickEvent('tango-viewer: dismiss', '');
    this.setUrlQueryString('', '');
    this.modalCtrl.dismiss();
  }

  async openEllipsisMenu(ev) {
    const pop = await this.popCtrl.create({
      component: ContentViewerModalEllipsisMenuPage,
      event: ev,
      componentProps: {
        content: this.content,
        userActivity: this.userActivity
      }
    });

    await pop.present();
  }

  async setFavorite() {
    this.analytics.clickEvent('app-hero: set-favorite: ' + !this.userActivity.isFavorite, this.content.name);
    this.userActivity.isFavorite = !this.userActivity.isFavorite;
    await firstValueFrom(this.activityService.update(this.authUser, this.userActivity));

    if (this.userActivity.isFavorite) {
      this.notifications.showToast('Content added to favorites.');
    } else {
      this.notifications.showToast('Content removed from favorites.');
    }
  }

}
