import { Component, Input, OnInit } from '@angular/core';
import { FileUploader, FileDropDirective, FileLikeObject, FileUploaderOptions } from 'ng2-file-upload';

/**
 * ID: bh-file-uploader
 * Name: BH File Uploader
 * Description: Drag-and-drop + mobile-friendly file upload control
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 */
@Component({
  selector: 'bh-file-uploader',
  templateUrl: './bh-file-uploader.component.html',
  styleUrls: ['./bh-file-uploader.component.scss'],
})
export class BhFileUploaderComponent implements OnInit {
  @Input() backgroundImageURL = '';
  @Input() uploadLabel = 'Drag JPG, JPEG, or PNG image here';
  @Input() allowedMimeTypes: string[] = ['image/jpeg', 'image/jpg', 'image/png'];
  acceptedTypes = '';
  uploader: FileUploader;
  hasBaseDropZoneOver = false;

  constructor() {
  }

  ngOnInit() {
    const uploaderOptions: FileUploaderOptions = {
      allowedMimeType: this.allowedMimeTypes,
      // allowedFileType: this.allowedMimeTypes,
      queueLimit: 1,
      url: ''
    };
    this.uploader = new FileUploader(uploaderOptions);
    this.acceptedTypes = this.allowedMimeTypes.join(',');
  }

  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map((fileItem) => {
      return fileItem.file;
    });
  }

  fileOverBase(ev): void {
    this.hasBaseDropZoneOver = ev;
    // console.log('fileOverBase', this.uploader)
  }

  openFileBrowser(ev: any) {
    ev.preventDefault();
    const element : HTMLElement = document.getElementById('fileUpload') as HTMLElement;
    element.click();
  }

  removeItem(item) {
    // console.log('removing item', item, this.uploader.queue);
    this.uploader.removeFromQueue(item);
    // this.uploader = new FileUploader({
    //   allowedMimeType: ['image/jpeg', 'image/png'],
    //   queueLimit: 1,
    //   url: ''
    // });
  }

}
