import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContentTypeOption } from 'src/app/models/content-type-option';
import { ContentService } from 'src/app/services/content/content.service';

@Component({
  selector: 'bh-content-type-select',
  templateUrl: './bh-content-type-select.component.html',
  styleUrls: ['./bh-content-type-select.component.scss'],
})
export class BhContentTypeSelectComponent implements OnChanges {
  @Input() selectedTypeValue: string;
  @Input() readOnly = false;
  @Input() label = 'Content Type';
  @Output() selectEvent = new EventEmitter();
  selectedContentType;

  contentTypeOptions: ContentTypeOption[] = [];

  constructor(
    private contentService: ContentService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.contentTypeOptions = this.contentService.getContentTypes();
    if ('selectedTypeValue' in changes) {
      const option = this.contentTypeOptions.find(o => o.value === this.selectedTypeValue);
      // console.log('ngOnChanges: selectedOption', this.selectedTypeValue, this.contentTypeOptions, option);
      this.selectedContentType = option;
    }
  }

  selectContentType(option) {
   this.selectedContentType = option;
   this.selectEvent.emit(this.selectedContentType);
  }

}
