import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentContainer } from 'src/app/models/container';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Component({
  selector: 'bh-app-tile',
  templateUrl: './bh-app-tile.component.html',
  styleUrls: ['./bh-app-tile.component.scss'],
})
export class BhAppTileComponent implements OnInit {
  @Input() appContainer: ContentContainer;
  @Input() classContainer: ContentContainer;
  @Input() categoryContainer: ContentContainer;
  @Input() analyticsSource: string = 'app-tile';
  @Input() showMoveButtons = true;
  @Input() showEditButton = true;
  @Input() showAudienceBadge = false;
  @Output() openEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() moveEvent = new EventEmitter();
  authUser: User;

  constructor(
    private analytics: BhAnalyticsService,
    private navService: NavigationService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authUser = this.authService.getAuthUser();
  }

  openApp() {
    this.analytics.clickEvent(this.analyticsSource + ': open-app: ', this.appContainer.name);

    setTimeout(() => {
      if (this.classContainer && this.categoryContainer) {
        this.navService.navigateForward('/tabs/explore/' +
          this.classContainer.id + '/' +
          this.categoryContainer.id + '/' +
          this.appContainer.id
        );
      } else {
        this.navService.navigateForward('/tabs/apps/' +
          this.appContainer.id
        );
      }
    }, 200);
  }

  async editApp(ev) {
    ev.stopPropagation();
    this.editEvent.emit(this.appContainer);
  }

  moveApp(direction: 'up' | 'down', ev) {
    ev.stopPropagation();
    this.moveEvent.emit({ appContainer: this.appContainer, direction });
  }
}
