import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BhAppLogoComponentModule } from '../_core/bh-app-logo/bh-app-logo.component.module';
import { BhUserBadgeComponentModule } from '../_core/bh-user-badge/bh-user-badge.component.module';
import { BhSpinnerComponentModule } from '../_core/bh-spinner/bh-spinner.component.module';
import { BhCategoryContainerComponentModule } from '../bh-category-container/bh-category-container.component.module';
import { BhIconSelectComponent } from './bh-icon-select.component';

@NgModule({
  declarations: [
    BhIconSelectComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BhAppLogoComponentModule,
    BhUserBadgeComponentModule,
    BhSpinnerComponentModule,
    BhCategoryContainerComponentModule,
  ],
  exports: [
    BhIconSelectComponent
  ]
})
export class BhIconSelectComponentModule { }
