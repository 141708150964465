import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription, firstValueFrom } from 'rxjs';
import { SelectOption } from 'src/app/models/_core/select-option';
import { Content } from 'src/app/models/content';
import { ContentType } from 'src/app/models/content-type';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ContentService } from 'src/app/services/content/content.service';
import * as moment from 'moment';
import { Icon } from 'ionicons/dist/types/components/icon/icon';
import { BhFileUploaderComponent } from 'src/app/components/_core/bh-file-uploader/bh-file-uploader.component';
import { ContainerTag, ContentTag, Tag } from 'src/app/models/tag';
import { ContentContainer } from 'src/app/models/container';
import { ManageTagEditorPage } from '../manage-tag-editor/manage-tag-editor.page';
import { ManageTagListPage } from '../manage-tag-list/manage-tag-list.page';
import { ContainerType } from 'src/app/models/container-type';
import { ContainerService } from 'src/app/services/container/container.service';
import { ManageContainerEditorPage } from '../manage-container-editor/manage-container-editor.page';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { ContentSectionService } from 'src/app/services/content-section/content-section.service';
import { ErrorHandlerService } from 'src/app/services/_core/error-handler/error-handler.service';
import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { ConstantsService } from 'src/app/services/_core/constants/constants.service';

@Component({
  selector: 'app-manage-content-editor',
  templateUrl: './manage-content-editor.page.html',
  styleUrls: ['./manage-content-editor.page.scss'],
})
export class ManageContentEditorPage implements OnInit {
  @Input() editMode: 'new' | 'edit' = 'new';
  @Input() contentType: ContentType;
  @Input() contentId: string;
  @Input() appType: 'APP' | 'GOLIVE' = 'APP';
  @Input() classContainerId: string;
  @Input() categoryContainerId: string;
  @Input() appContainerId: string;
  @Input() tabContainerId: string;
  @Input() parentContainerId: string;
  @ViewChild(BhFileUploaderComponent) fileUploader: BhFileUploaderComponent;
  modalTitle = '';
  contentTypeName = '';
  content: Content = {};
  showEndDateTime = false;
  showLinkUrl = false;
  uploaderBackgroundImage = '';
  iconSelectLabel = 'Document Icon';
  isUpload = false;
  allowedMimeTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
  sectionTags: ContentTag[] = [];
  coreTags: ContentTag[] = [];
  isHomeClassContent = false;
  appContainer: ContentContainer;

  form1: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    description: [null],
    durationMin: [null],
    text: [null],
    keywords: [null],
    featured: [null],
    status: [null, Validators.required],
    startDate: [null, Validators.required],
    startTime: [null, [Validators.required, Validators.pattern(this.constants.validatorParams.militaryTimePattern)]],
    showEndDateTime: [null],
    endDate: [null, Validators.required],
    endTime: [null, [Validators.required, Validators.pattern(this.constants.validatorParams.militaryTimePattern)]],
    showRedirectUrl: [null],
    eventUrl: [null],
    url: [null],
    videoId: [null],
    // path: [null],
    class: [Validators.required],
    category: [Validators.required],
    application: [Validators.required],
    tab: [Validators.required],
  });
  subscriptions: Subscription[] = [];
  showErrorMessage = false;
  submitAttempted = false;
  validationMessages = {
    name: [{ type: 'required', message: 'Name is required.' }],
    status: [{ type: 'required', message: 'Status is required.' }],
    startDate: [{ type: 'required', message: 'Post Date is required.' }],
    startTime: [
      { type: 'required', message: 'Post Time is required.' },
      { type: 'pattern', message: 'Use military time format: HH:mm' },
    ],
    endDate: [{ type: 'required', message: 'Takedown Date is required.' }],
    endTime: [
      { type: 'required', message: 'Takedown Time is required.' },
      { type: 'pattern', message: 'Use military time format: HH:mm' },
    ],
    class: [{ type: 'required', message: 'Content Class is required.' }],
    category: [{ type: 'required', message: 'Category is required.' }],
    application: [{ type: 'required', message: 'Application is required.' }],
    tab: [{ type: 'required', message: 'Tab is required.' }],
  };
  statusOptions: SelectOption[] = [
    {
      label: 'Published',
      value: 'PUBLISHED',
      active: 1
    },
    {
      label: 'Draft',
      value: 'DRAFT',
      active: 1
    },
    {
      label: 'Archived',
      value: 'ARCHIVED',
      active: 1
    },
    {
      label: 'Deleted',
      value: 'DELETED',
      active: 1
    },
  ];

  classOptions: SelectOption[] = [];
  categoryOptions: SelectOption[] = [];
  applicationOptions: SelectOption[] = [];
  tabOptions: SelectOption[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private contentService: ContentService,
    private containerService: ContainerService,
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private notifications: NotificationsService,
    private navService: NavigationService,
    private contentSectionSvc: ContentSectionService,
    private errorHandler: ErrorHandlerService,
    private helpers: HelperUtilitiesService,
    private constants: ConstantsService
  ) { }

  async ngOnInit() {
    await this.setEditMode();
    this.subscribeToControls();
  }

  async setEditMode(): Promise<void> {
    const typeName = this.contentService.translateContentTypeCode(this.contentType);
    this.contentTypeName = typeName;
    this.iconSelectLabel = this.contentType === 'BANNER' ? 'Announcement Icon' : 'Document Icon';
    this.isHomeClassContent = (this.classContainerId === this.navService.homeClass.id);
    if (this.contentType === 'EVENT') {
      this.showEndDateTime = true;
      this.form1.controls.showEndDateTime.setValue(true);
    }
    if (this.editMode === 'edit' && this.contentId) {
      this.modalTitle = 'Edit ' + typeName;
      await this.loadData();
    } else {
      this.modalTitle = 'New ' + typeName;
      this.setContentType(this.contentType);
      const defaultStartTimeMoment = moment();
      const defaultEndDateTimeMoment = moment().add(100, 'years');
      this.form1.controls.startDate.setValue(defaultStartTimeMoment);
      this.form1.controls.startTime.setValue('00:00');
      this.form1.controls.endDate.setValue(defaultEndDateTimeMoment);
      this.form1.controls.endTime.setValue('23:59');
      this.form1.controls.featured.setValue(false);
      this.form1.controls.status.setValue('PUBLISHED');

      // Set default banner icon
      if (this.contentType === 'BANNER') {
        this.content.icon = 'alert-circle-outline';
      }

      // Load Classes
      await this.loadContainerOptions('CLASS', null);
      this.form1.controls.class.setValue(this.classContainerId);

      // Load Categories
      if (this.classContainerId) {
        await this.loadContainerOptions('CATEGORY', this.classContainerId);
        this.form1.controls.category.setValue(this.categoryContainerId);
      }

      // Load Applications
      if (this.appContainerId) {
        await this.loadContainerOptions(this.appType, this.categoryContainerId);
        this.form1.controls.application.setValue(this.appContainerId);
      }

      // Load Tabs
      if (this.appContainerId) {
        await this.loadContainerOptions('TAB', this.appContainerId);
        this.form1.controls.tab.setValue(this.tabContainerId);
      }

      // Load Tabs for Go-Live/Event Pages
      if (this.isHomeClassContent && this.appContainerId) {
        // console.log('Go-Live/Event Pages: tabContainer', this.tabContainerId);
        await this.loadContainerOptions('TAB', this.appContainerId);
        if (this.tabContainerId) {
          this.form1.controls.tab.setValue(this.tabContainerId);
        }
      }
    }
    return Promise.resolve();
  }

  subscribeToControls() {
    this.subscriptions.push(
      this.form1.controls.showRedirectUrl.valueChanges.subscribe(val => {
        this.showLinkUrl = val;
      }),
      this.form1.controls.showEndDateTime.valueChanges.subscribe(val => {
        let endMoment = val ? moment(): moment().add(100, 'years');
        this.form1.controls.endDate.setValue(endMoment.format('YYYY-MM-DD'));
        this.form1.controls.endTime.setValue(endMoment.format('HH:mm'));
        this.showEndDateTime = val;
      }),
      this.form1.controls.class.valueChanges.subscribe(val => {
        this.categoryOptions = [];
        this.form1.controls.category.setValue('');
        this.classContainerId = val;
        this.loadContainerOptions('CATEGORY', val)
        this.applicationOptions = [];
        this.form1.controls.application.setValue('');
        this.tabOptions = [];
        this.form1.controls.tab.setValue('');
      }),
      this.form1.controls.category.valueChanges.subscribe(async val => {
        this.applicationOptions = [];
        this.categoryContainerId = val;
        this.loadContainerOptions(this.appType, val)
        this.form1.controls.application.setValue('');
        this.tabOptions = [];
        this.form1.controls.tab.setValue('');
      }),
      this.form1.controls.application.valueChanges.subscribe(async val => {
        this.tabOptions = [];
        this.appContainerId = val;
        this.loadContainerOptions('TAB', val)
        this.form1.controls.tab.setValue('');
      }),
    );
  }

  async loadData(): Promise<boolean> {
    try {
      const res = await firstValueFrom(this.contentService.getById(this.contentId));
      if (res) {
        this.content = res;
        this.form1.controls.name.setValue(res.name);
        this.form1.controls.description.setValue(res.description);
        this.form1.controls.text.setValue(res.text);
        this.form1.controls.durationMin.setValue(res.durationMin);
        this.form1.controls.keywords.setValue(res.keywords.join(', '));
        this.form1.controls.featured.setValue(res.featured === 'HOME' ? true : false);
        this.form1.controls.status.setValue(res.status);
        this.showLinkUrl = !!res.url;
        this.form1.controls.showRedirectUrl.setValue(this.showLinkUrl);
        this.form1.controls.url.setValue(this.contentType !== 'BANNER' ? (res.url ? res.url : res.icon) : res.url);
        this.form1.controls.eventUrl.setValue(this.contentType !== 'BANNER' ? (res.url ? res.url : res.icon) : res.url);
        this.form1.controls.videoId.setValue(res.videoId);

        // Load Start DateTime
        const startDateTimeMoment = this.helpers.convertUtcToLocal(moment(res.startDateTime), undefined);
        this.form1.controls.startDate.setValue(startDateTimeMoment);
        this.form1.controls.startTime.setValue(startDateTimeMoment.format('HH:mm'));

        // Load End DateTime
        const endDateTimeMoment = this.helpers.convertUtcToLocal(moment(res.endDateTime), undefined);
        this.form1.controls.endDate.setValue(endDateTimeMoment);
        this.form1.controls.endTime.setValue(endDateTimeMoment.format('HH:mm'));
        const nowMoment = moment();
        this.showEndDateTime = endDateTimeMoment.diff(nowMoment, 'years') <= 10;

        if (!this.classContainerId) {
          this.classContainerId = this.content.classContainerId;
        }

        if (!this.categoryContainerId) {
          this.categoryContainerId = this.content.subCategoryContainerId;
        }

        if (!this.appContainerId) {
          this.appContainerId = this.content.appContainerId;
        }

        if (!this.tabContainerId) {
          this.tabContainerId = this.content.tabContainerId;
        }

        // Load Content Classes
        await this.loadContainerOptions('CLASS', null);
        this.form1.controls.class.setValue(this.content.classContainerId);

        // Load Categories
        if (this.content.classContainerId) {
          await this.loadContainerOptions('CATEGORY', this.content.classContainerId);
          this.form1.controls.category.setValue(this.content.subCategoryContainerId);
        }

        // Load Applications
        if (this.content.subCategoryContainerId) {
          // await this.loadAppContainer();
          await this.loadContainerOptions(this.appType, this.content.subCategoryContainerId);
          this.form1.controls.application.setValue(this.content.appContainerId);
        }

        // Load Tabs
        if (this.content.appContainerId) {
          await this.loadContainerOptions('TAB', this.content.appContainerId);
          this.form1.controls.tab.setValue(this.content.tabContainerId);
        }
        this.setTags();
        this.setContentType(this.content.type);
      } else {
        this.modalCtrl.dismiss();
      }
    } catch (err) {
      this.modalCtrl.dismiss();
      this.errorHandler.handleError(err, 'manage-content-editor.loadData');
    }
    return Promise.resolve(true);
  }

  setTags() {
    if (this.content.ContentTags) {
      this.sectionTags = this.content.ContentTags.filter(c => c.Tag.type === 'SECTION' && c.isActive);
      this.sectionTags.forEach(async st => {
        if (st.tabContainerId) {
          const tabContainer = await firstValueFrom(this.containerService.getById(st.tabContainerId));
          st.tabContainer = tabContainer;
        }
      });
      this.coreTags = this.content.ContentTags.filter(c => c.Tag.type === 'TAG' && c.isActive);
    }
  }

  setContentType(contentTypeOption) {
    let contentType = (contentTypeOption && contentTypeOption.value) ? contentTypeOption.value : contentTypeOption;
    this.content.type = contentType;

    // Check for content upload
    if (
      contentType === 'LINK' &&
      this.content &&
      (
        this.content.url &&
        this.content.url.indexOf('worktips.apps.baystatehealth.org/uploaded-images/') > -1
      ) ||
      (
        this.content.icon &&
        this.content.icon.indexOf('worktips.apps.baystatehealth.org/uploaded-images/') > -1
      )
    ) {
      // this.contentType = 'UPLOAD';
      contentType = 'UPLOAD';
      this.isUpload = true;
    }

    switch (contentType) {
      case 'UPLOAD':
      case 'LINK':
        this.contentType = 'LINK';
        this.isUpload = (contentType === 'UPLOAD');
        this.showLinkUrl = !this.isUpload;
        this.modalTitle = this.editMode + ' ' + ((contentType === 'UPLOAD') ? 'Upload' : this.contentService.translateContentTypeCode(this.contentType));
        break;

      default:
        this.contentType = contentType;
        this.isUpload = false;
        this.showLinkUrl = false;
        this.modalTitle = this.editMode + ' ' + this.contentService.translateContentTypeCode(this.contentType);
    }

    this.setValidators(contentType);
  }

  setValidators(contentType) {
    switch (contentType) {
      case 'LINK':
        this.form1.controls.url.setValidators([Validators.required]);
        this.form1.controls.url.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        break;

      case 'BANNER':
        this.form1.controls.text.setValidators([Validators.required]);
        this.form1.controls.text.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        break;

      case 'VIDEO':
      case 'TANGO':
        this.form1.controls.videoId.setValidators([Validators.required]);
        this.form1.controls.videoId.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        break;

      default:
        this.form1.controls.text.clearValidators();
        this.form1.controls.text.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        this.form1.controls.url.clearValidators();
        this.form1.controls.url.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        this.form1.controls.videoId.clearValidators();
        this.form1.controls.videoId.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
  }

  async loadContainerOptions(containerType: ContainerType, parentId: string) {
    try {
      const res = await firstValueFrom(this.containerService.getAll(containerType, parentId, false));
      if (res && Array.isArray(res) && res.length > 0) {
        const containers = res;
        switch (containerType) {
          case 'CLASS':
            this.classOptions = [];
            this.categoryOptions = [];
            this.applicationOptions = [];
            this.tabOptions = [];
            for (const c of containers) {
              const selectOption: SelectOption = {
                label: c.name,
                value: c.id
              };
              this.classOptions.push(selectOption);
            }
            break;

          case 'CATEGORY':
            this.categoryOptions = [];
            this.applicationOptions = [];
            this.tabOptions = [];
            for (const c of containers) {
              const selectOption: SelectOption = {
                label: c.name,
                value: c.id
              };
              this.categoryOptions.push(selectOption);
            }
            break;

          case 'APP':
          case 'GOLIVE':
            this.applicationOptions = [];
            this.tabOptions = [];
            for (const c of containers) {
              const selectOption: SelectOption = {
                label: c.name,
                value: c.id
              };
              this.applicationOptions.push(selectOption);
            }
            break;

          case 'TAB':
            this.tabOptions = [];
            for (const c of containers) {
              const selectOption: SelectOption = {
                label: c.name,
                value: c.id
              };
              this.tabOptions.push(selectOption);
            }
            break;
        }
      }
      return Promise.resolve(true);
      // this.classes = this.testClasses;
    } catch (err) {
      this.errorHandler.handleError(err, 'loadContainerType');
      return Promise.reject();
    }
  }

  async addContainer(containerType: ContainerType) {
    let parentId = null;
    const classContainerId = this.form1.controls.class.value;
    const categoryContainerId = this.form1.controls.category.value;
    const appContainerId = this.form1.controls.application.value;
    const tabContainerId = this.form1.controls.tab.value;

    switch (containerType) {
      case 'CATEGORY':
        parentId = classContainerId;
        break;

      case 'APP':
        parentId = categoryContainerId;
        break;

      case 'TAB':
        parentId = appContainerId;
        break;
    }

    this.analytics.clickEvent('manage-content-editor > Add ' + containerType, '');
    const modal = await this.modalCtrl.create({
      component: ManageContainerEditorPage,
      cssClass: 'wide-modal',
      componentProps: {
        editMode: 'new',
        containerType,
        parentId,
        classContainerId,
        categoryContainerId,
        appContainerId,
      }
    });

    modal.onDidDismiss().then(async (data) => {
      if (data.data && data.data.isSaved) {
        const newId = data.data.id;
        let parentId = null;

        switch (containerType) {
          case 'CLASS':
            await this.loadContainerOptions('CLASS', parentId);
            this.form1.controls.class.setValue(newId);
            break;

          case 'CATEGORY':
            parentId = this.form1.controls.class.value;
            this.loadContainerOptions('CATEGORY', parentId)
            this.form1.controls.category.setValue(newId);
            break;

          case 'APP':
            parentId = this.form1.controls.category.value;
            this.loadContainerOptions(this.appType, parentId);
            this.form1.controls.application.setValue(newId);
            break;

          case 'TAB':
            parentId = this.form1.controls.application.value;
            this.loadContainerOptions('TAB', parentId);
            this.form1.controls.tab.setValue(newId);
            break;
        }

      }
    });

    await modal.present();

  }


  setIconSelection(icon: Icon) {
    this.content.icon = icon.name;
  }

  async save() {
    // console.log('saved: ', this.form1);
    this.submitAttempted = true;
    if (this.form1.valid) {
      this.notifications.startLoading('Saving your work');
      try {
        // Update container properties
        this.content.type = this.contentType;
        this.content.name = this.form1.controls.name.value;
        this.content.description = this.form1.controls.description.value;
        this.content.durationMin = this.form1.controls.durationMin.value;
        this.content.featured = this.form1.controls.featured.value ? 'HOME' : null;
        this.content.text = this.form1.controls.text.value;
        const keywords = this.form1.controls.keywords.value;
        this.content.keywords = keywords ? (keywords.indexOf(',') > -1 ? keywords.split(',') : [keywords]) : [''];
        if (this.content.keywords && this.content.keywords.length > 0) {
          for (let k of this.content.keywords) {
            if (k) {
              k = k.trim();
            } else {
              k = '';
            }
          }
        }
        // Define container paths
        const contentClassId = this.form1.controls.class.value;
        const categoryClassId = this.form1.controls.category.value;
        const appContainerId = this.form1.controls.application.value;
        const tabContainerId = this.form1.controls.tab.value;
        const parentId =
          // If Banner, use appContainerId (banners are not associated with tabs)
          this.contentType === 'BANNER' ? appContainerId :
            // If anything else, use tabContainerId
            tabContainerId ? tabContainerId :
              // If no tabContainerId, use homeClass Id
              this.navService.homeClass.id;
        this.content.parentId = parentId;
        this.content.appContainerId = appContainerId;
        // If go-live or event content, seed classContainer to root home class container
        this.content.classContainerId = (this.contentType === 'GOLIVE' || this.contentType === 'EVENT') ?
          this.navService.homeClass.id :
          contentClassId;
        this.content.subCategoryContainerId = categoryClassId;
        this.content.tabContainerId = tabContainerId;

        // Set status properties
        this.content.status = this.form1.controls.status.value;

        // Parse Date Format for UTC conversion
        const parseDateFormat = 'YYYY-MM-DD HH:mm';

        // Update Start Date/Time
        const startDate = this.form1.controls.startDate.value;
        const startTime = this.form1.controls.startTime.value;
        const startDateTime = startDate.format('YYYY-MM-DD') + ' ' + startTime;
        const startMoment = moment(startDateTime, parseDateFormat);
        const startMomentUtc = this.helpers.convertLocalToUtc(startMoment.format(parseDateFormat), parseDateFormat);
        console.log('startDateTime', startDate, startTime, startMoment, startMomentUtc);
        this.content.startDateTime = startMomentUtc.toISOString(true);

        // Update End Date/Time
        const endDate = this.form1.controls.endDate.value;
        const endTime = this.form1.controls.endTime.value;
        console.log('endDate', endDate);
        const endDateTime = endDate.format('YYYY-MM-DD') + ' ' + endTime;
        const endMoment = moment(endDateTime, parseDateFormat);
        const endMomentUtc = this.helpers.convertLocalToUtc(endMoment.format(parseDateFormat), parseDateFormat);
        console.log('endDateTime', endDate, endTime, endMoment, endMomentUtc);
        this.content.endDateTime = endMomentUtc.toISOString(true);
        this.content.isActive = true;

        // Set behavior properties
        this.content.url = this.contentType === 'EVENT' ?
          this.form1.controls.eventUrl.value :
          this.form1.controls.url.value;
        this.content.security = 'INTERNAL';
        this.content.videoId = this.form1.controls.videoId.value;
        const files = this.fileUploader ? this.fileUploader.getFiles() : null;

        // Save container
        if (this.editMode === 'edit' && this.contentId) {
          const res = await firstValueFrom(this.contentService.update(this.content));
        } else {
          const res = await firstValueFrom(this.contentService.create(this.content));
          this.content.id = res.id;
        }

        // Check for file upload
        if (files && files.length === 1) {
          this.content.url = files[0].name;
          const rawFile = files[0].rawFile;
          const res = await firstValueFrom(this.contentService.uploadFile(this.content, rawFile));
        }

        this.analytics.clickEvent('manage-content-editor: save', '');
        this.notifications.stopLoading();
        this.notifications.showToast('Saved successfully.');
        this.modalCtrl.dismiss({ isSaved: true, contentType: this.contentType });
      } catch (err) {
        this.notifications.stopLoading();
        this.errorHandler.handleError(err, 'manage-content-editor.save');
      }
    }
  }

  async addTag(tagType: 'SECTION' | 'TAG') {
    this.analytics.clickEvent('manage-content-editor > Add tag/section', '');
    const modal = await this.modalCtrl.create({
      component: ManageTagListPage,
      componentProps: {
        // editMode: 'new',
        tagType,
        classContainerId: this.classContainerId,
        categoryContainerId: this.categoryContainerId,
        appContainerId: this.appContainerId,
        tabContainerId: this.tabContainerId,
        parentContainerId: this.parentContainerId,
      }
    });

    modal.onDidDismiss().then(async (data) => {
      // console.log('New Tag dismissed', data);
      if (data && data.data && data.data.isSaved) {
        if (data.data.tag) {
          const newContentTag: ContentTag = {
            Tag: data.data.tag,
            contentId: this.content.id,
            tabContainerId: (tagType === 'SECTION') ? this.tabContainerId : null,
            isActive: true,
            tagId: data.data.tag.id
          };

          // Save section
          if (newContentTag.Tag.type === 'SECTION') {
            await this.saveSection(newContentTag);
          }

          // Update tags
          if (this.content && !this.content.ContentTags) {
            // Initialize ContentTags array
            this.content.ContentTags = [];
          }
          this.content.ContentTags.push(newContentTag);
          this.setTags();
          // console.log('New Tag: Dismiss: ', this.content);
        }
      }
    });

    await modal.present();
  }

  async saveSection(contentTag: ContentTag): Promise<any> {
    try {
      const tag = await firstValueFrom(this.contentSectionSvc.create(contentTag));
      Promise.resolve(tag);
    } catch (err) {
      Promise.reject(err);
    }
  }

  async editTag(contentTag: ContentTag) {
    this.analytics.clickEvent('list-card > Edit section', contentTag.Tag.name);
    const modal = await this.modalCtrl.create({
      component: ManageTagEditorPage,
      cssClass: 'wide-modal',
      componentProps: {
        editMode: 'edit',
        tagType: contentTag.Tag.type,
        tagId: contentTag.Tag.id,
        classContainerId: this.classContainerId,
        categoryContainerId: this.categoryContainerId,
        appContainerId: this.appContainerId,
        tabContainerId: this.tabContainerId,
        parentContainerId: this.parentContainerId,
        activeSegment: 'form'
      }
    });

    modal.onDidDismiss().then((data) => {
      if (data && data.data && data.data.isSaved) {
        this.loadData();
      }
    });
    await modal.present();
  }

  async removeTag(contentTag: ContentTag, ev) {
    ev.stopPropagation();
    switch (contentTag.Tag.type) {
      case 'SECTION':
        await this.removeSection(contentTag);
        break;

      case 'TAG':
      case 'GOLIVE':
        for (const t of this.content.ContentTags) {
          if (t.Tag.name === contentTag.Tag.name) {
            t.isActive = false;
          }
        }
        this.setTags();
        break;
    }
  }

  async removeSection(contentTag: ContentTag): Promise<void> {
    try {
      contentTag.isActive = false;
      await firstValueFrom(this.contentSectionSvc.update(contentTag));
      this.setTags();
    } catch (err) {
      this.errorHandler.handleError(err, 'manage-content-editor.removeSection()');
    }

  }

  hasFileUpload() {
    if (this.isUpload) {
      const files = this.fileUploader ? this.fileUploader.getFiles() : null;
      return files && files.length === 0 ? true : false;
    } else {
      return false;
    }
  }

  dismiss() {
    this.analytics.clickEvent('manage-content-editor: dismiss', '');
    this.modalCtrl.dismiss();
  }

}
