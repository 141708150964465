import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Content } from 'src/app/models/content';
import { User } from 'src/app/models/user';
import { UserActivity } from 'src/app/models/user-activity';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { UserActivityService } from 'src/app/services/user-activity/user-activity.service';
import { firstValueFrom } from 'rxjs';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { ErrorHandlerService } from 'src/app/services/_core/error-handler/error-handler.service';

@Component({
  selector: 'app-content-feedback-modal',
  templateUrl: './content-feedback-modal.page.html',
  styleUrls: ['./content-feedback-modal.page.scss'],
})
export class ContentFeedbackModalPage implements OnInit {
  @Input() content: Content;
  userActivity: UserActivity;
  authUser: User;

  constructor(
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController,
    private activityService: UserActivityService,
    private authService: AuthService,
    private notifications: NotificationsService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.analytics.screenView('content-feedback-modal', '');
    this.authUser = this.authService.getAuthUser();
    this.loadActivityRecord();
  }


  async loadActivityRecord() {
    try {
      // Try loading record
      const res = await firstValueFrom(this.activityService.getByUserAndContentId(this.authUser, this.content));
      if (res && Array.isArray(res) && res.length > 0) {
        // console.log('loadActivityRecord', res);
        this.userActivity = res[res.length - 1];
      } else {
        // Record doesn't exist, create record
        const newActivity: UserActivity = {
          userId: this.authUser.userId,
          userName: this.authUser.userName,
          contentId: this.content.id,
          isFavorite: false,
          status: 'STARTED',
          isActive: true
        };
        const createRes = await firstValueFrom(this.activityService.create(this.authUser, newActivity));
        if (createRes) {
          // console.log('createdRess', createRes);
          this.userActivity = createRes;
        }
      }
    } catch (err) {
      this.errorHandler.handleError(err, 'loadActivityRecord');
    }
  }


  dismiss() {
    this.analytics.clickEvent('content-feedback: dismiss', '');
    this.modalCtrl.dismiss();
  }

}
