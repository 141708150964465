<div class="app-logo"
[ngClass]="
{
  'small': size === 'small',
  'medium': size === 'medium',
  'responsive': responsive}
  ">
  <div class="app-logo-icon" [ngClass]="{'transparent-icon': transparentIcon}"></div>
  <div class="app-logo-name" [ngStyle]="{ 'color': color }">
    <span class="app-logo-name-text">{{ appName }}</span>
    <div class="dev-badge-wrapper" *ngIf="!env.production">
      <a href="javascript:void(0)" (click)="devInfo()" class="dev-badge">DEV <span class="version-label" *ngIf="size !== 'medium'">version</span></a>
    </div>
  </div>
</div>
