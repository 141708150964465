<ion-header class="modal-header">
  <ion-toolbar>
    <ion-title>Manage Users</ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" (click)="dismiss()" size="small"
        ><ion-icon name="close-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div class="search-toolbar">
    <ion-searchbar
      mode="ios"
      placeholder="Search users..."
      (ionInput)="search($event)"
      [debounce]="200"
    ></ion-searchbar>
    <div class="add-button-wrapper">
      <ion-button fill="outline" (click)="add()">
        Add User
      </ion-button>
    </div>
  </div>
</ion-header>

<ion-content class="ion-padding">
  <bh-spinner
    type="skeleton"
    [skeletonItemCount]="3"
    *ngIf="isLoading"
  ></bh-spinner>
  <ion-list *ngIf="!isLoading">
    <ng-container *ngFor="let u of users">
      <ion-item button (click)="edit(u)" *ngIf="!u.hide">
        <ion-icon name="person-outline"></ion-icon>
        <ion-label>
          {{ u.lastName }}, {{ u.firstName }}
          <div class="details">{{ u.userName }}</div>
          <div class="details">{{ u.roles.join(', ') }}</div>
        </ion-label>
        <ion-badge color="danger" *ngIf="!u.isActive">Inactive</ion-badge>
      </ion-item>
    </ng-container>

    <p *ngIf="!users || users.length === 0">Users will display here.</p>
  </ion-list>
</ion-content>
