import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bh-editor-toolbox',
  templateUrl: './bh-editor-toolbox.component.html',
  styleUrls: ['./bh-editor-toolbox.component.scss'],
})
export class BhEditorToolboxComponent  implements OnInit {
  @Input() heading = '';
  @Input() detail = '';


  constructor() { }

  ngOnInit() {}

}
