<ion-header class="modal-header mobile-only">
  <ion-toolbar>
    <ion-title>
      <div class="icon-and-title">
        <ion-icon name="person-outline"></ion-icon>
        {{ modalTitle }}
      </div>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" (click)="dismiss()" size="small"
        ><ion-icon name="close-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="two-panel">
    <div class="information-panel desktop-only">
      <h1>{{ modalTitle }}</h1>
      <p>Edit the properties of this user.</p>
    </div>
    <div class="form-panel">
      <div
        class="form-panel-inner"
        [ngClass]="{'with-header': editMode === 'edit'}"
      >
        <div
          class="form-panel-header desktop-only-flex"
          *ngIf="editMode === 'edit'"
        ></div>
        <a
          href="javascript:void(0)"
          (click)="dismiss()"
          class="close-button desktop-only"
        >
          <ion-icon name="close-outline"></ion-icon>
        </a>

        <ng-container>
          <form [formGroup]="form1">
            <div class="user-impersonate-container">
              <bh-input
                [formGroup]="form1"
                type="ion-text"
                formControlName="userName"
                placeholder=""
                label="User Name"
                position="stacked"
                [value]=""
                [readonly]="editMode === 'edit'"
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>
              <ion-button fill="outline" (click)="impersonate()" *ngIf="editMode === 'edit' && authUser?.role === 'SYS_ADMIN'">
                Impersonate
              </ion-button>
            </div>

            <ng-container
              *ngIf="form1.controls.userName.value && initUserName !== form1.controls.userName.value"
            >
              <div class="check-status" *ngIf="checkStatus === 'checking'">
                <ion-spinner name="crescent" size="small"></ion-spinner>
                Checking for existing user
              </div>

              <div class="check-status success" *ngIf="checkStatus === 'new'">
                <ion-icon name="checkmark-circle-outline"></ion-icon>
                This user an be added.
              </div>

              <div
                class="check-status warning"
                *ngIf="checkStatus === 'exists'"
              >
                <ion-icon name="alert-circle-outline"></ion-icon>
                <span>This user exists and cannot be recreated.</span>
              </div>
            </ng-container>

            <ng-container *ngIf="formReady">
              <bh-input
                [formGroup]="form1"
                type="ion-text"
                formControlName="firstName"
                placeholder=""
                label="First Name"
                position="stacked"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>

              <bh-input
                [formGroup]="form1"
                type="ion-text"
                formControlName="lastName"
                placeholder=""
                label="Last Name"
                position="stacked"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>

              <bh-input
                [formGroup]="form1"
                type="ion-select"
                formControlName="role"
                placeholder=""
                label="Role"
                position="stacked"
                [selectOptions]="roleOptions"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>

              <bh-input
                [formGroup]="form1"
                type="ion-checkbox"
                formControlName="isActive"
                placeholder=""
                label="Active"
                position="stacked"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>
            </ng-container>
          </form>

          <ng-container *ngIf="editMode === 'edit'">
            <h3>Record Information</h3>
            <div class="record-row">
              <div class="record-label">ID</div>
              <div class="record-value">{{ user?.id }}</div>
            </div>
            <div class="record-row">
              <div class="record-label">Type</div>
              <div class="record-value">{{ user?.roles.join(', ') }}</div>
            </div>
            <div class="record-row" *ngIf="user?.CreatedByUser">
              <div class="record-label">Created By</div>
              <div class="record-value">
                {{ user?.CreatedByUser?.lastName }}, {{
                user?.CreatedByUser?.firstName }} ({{
                user?.CreatedByUser?.userName }})
              </div>
            </div>
            <div class="record-row">
              <div class="record-label">Created At</div>
              <div class="record-value">
                {{ user?.createdAt | formatTimestamp }}
              </div>
            </div>
            <div class="record-row" *ngIf="user?.UpdatedByUser">
              <div class="record-label">Updated By</div>
              <div class="record-value">
                {{ user?.UpdatedByUser?.lastName }}, {{
                user?.UpdatedByUser?.firstName }} ({{
                user?.UpdatedByUser?.userName }})
              </div>
            </div>
            <div class="record-row">
              <div class="record-label">Updated At</div>
              <div class="record-value">
                {{ user?.updatedAt | formatTimestamp }}
              </div>
            </div>
          </ng-container>
        </ng-container>
        <div class="form-panel-footer desktop-only">
          <!-- <div class="form-panel-footer-spacer"></div> -->
          <div class="form-panel-footer-inner">
            <ion-button
              [disabled]="!form1.valid || !isNameValid()"
              (click)="save()"
              >Save</ion-button
            >
            <ion-button fill="outline" (click)="dismiss()">Discard</ion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer class="mobile-only" *ngIf="formReady">
  <ion-button [disabled]="!form1.valid || !isNameValid()" (click)="save()"
    >Save</ion-button
  >
</ion-footer>
