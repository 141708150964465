<ion-header class="modal-header mobile-only">
  <ion-toolbar>
    <ion-title>
      <div class="icon-and-title">
        <ion-icon name="list-outline" *ngIf="tagType === 'SECTION'"></ion-icon>
        <ion-icon
          name="pricetag-outline"
          *ngIf="tagType === 'GOLIVE'"
        ></ion-icon>
        <ion-icon name="pricetag-outline" *ngIf="tagType === 'TAG'"></ion-icon>
        {{ modalTitle }}
      </div>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" (click)="dismiss()" size="small"
        ><ion-icon name="close-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-segment [(ngModel)]="activeSegment" *ngIf="editMode === 'edit'">
    <ion-segment-button value="form">
      <div class="inline-segment-label">
        <ion-icon name="settings-outline"></ion-icon>Properties
      </div>
    </ion-segment-button>
    <ion-segment-button value="content">
      <div class="inline-segment-label">
        <ion-icon name="list-outline"></ion-icon>Content
      </div></ion-segment-button
    >
  </ion-segment>
</ion-header>

<ion-content>
  <div class="two-panel">
    <div class="information-panel desktop-only">
      <h1>{{ modalTitle }}</h1>
      <p>Edit the properties of this {{ tagType?.toLowerCase() }}.</p>
      <p>&nbsp;</p>
      <p>
        <strong>Renaming {{ tagType?.toLowerCase() }}s</strong><br />
        <br />
        When renaming {{ tagType?.toLowerCase() }}s, the renamed {{
        tagType?.toLowerCase() }} will be applied to all associated documents
        and content. Please be careful when changing names.
      </p>
    </div>
    <div class="form-panel">
      <div
        class="form-panel-inner"
      >
        <!-- <div
          class="form-panel-header desktop-only-flex"
          *ngIf="editMode === 'edit'"
        >
          <div class="form-panel-header-spacer"></div>
          <div class="form-panel-header-inner">
            <ion-segment
              [(ngModel)]="activeSegment"
              *ngIf="editMode === 'edit'"
            >
              <ion-segment-button value="form">
                <div class="inline-segment-label">
                  <ion-icon name="settings-outline"></ion-icon>Properties
                </div>
              </ion-segment-button>
              <ion-segment-button value="content">
                <div class="inline-segment-label">
                  <ion-icon name="list-outline"></ion-icon>Content
                </div></ion-segment-button
              >
            </ion-segment>
          </div>
        </div> -->
        <a
          href="javascript:void(0)"
          (click)="dismiss()"
          class="close-button desktop-only"
        >
          <ion-icon name="close-outline"></ion-icon>
        </a>

        <ng-container *ngIf="activeSegment === 'form'">
          <form [formGroup]="form1">
            <bh-input
              [formGroup]="form1"
              type="ion-text"
              formControlName="name"
              placeholder=""
              label="Name"
              position="stacked"
              textTransform="normal"
              [maxlength]="100"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>

            <ng-container
              *ngIf="form1.controls.name.value && initTagName !== form1.controls.name.value"
            >
              <div class="check-status" *ngIf="checkStatus === 'checking'">
                <ion-spinner name="crescent" size="small"></ion-spinner>
                Checking for existing {{ tagType.toLowerCase() }}
              </div>

              <div class="check-status success" *ngIf="checkStatus === 'new'">
                <ion-icon name="checkmark-circle-outline"></ion-icon>
                This {{ tagType.toLowerCase() }} name is unique and can be used.
              </div>

              <div
                class="check-status warning"
                *ngIf="checkStatus === 'exists'"
              >
                <ion-icon name="alert-circle-outline"></ion-icon>
                <span
                  >This {{ tagType.toLowerCase() }} name is in use and cannot be
                  recreated. Use the existing one instead.</span
                >
              </div>
            </ng-container>

            <ng-container *ngIf="formReady">
              <bh-input
                [formGroup]="form1"
                type="ion-textarea"
                formControlName="description"
                placeholder=""
                label="Description"
                position="stacked"
                [maxlength]="200"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>

              <bh-input
                [formGroup]="form1"
                type="ion-checkbox"
                formControlName="isActive"
                placeholder=""
                label="Active"
                position="stacked"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>
            </ng-container>
          </form>

          <ng-container *ngIf="editMode === 'edit'">
            <h3>Record Information</h3>
            <div class="record-row">
              <div class="record-label">ID</div>
              <div class="record-value">{{ tag?.id }}</div>
            </div>
            <div class="record-row">
              <div class="record-label">Type</div>
              <div class="record-value">{{ tag?.type }}</div>
            </div>
            <div class="record-row">
              <div class="record-label">Sort Seq</div>
              <div class="record-value">{{ tag?.sort }}</div>
            </div>
            <div class="record-row">
              <div class="record-label">Created By</div>
              <div class="record-value">
                {{ tag?.CreatedByUser?.lastName }}, {{
                tag?.CreatedByUser?.firstName }} ({{
                tag?.CreatedByUser?.userName }})
              </div>
            </div>
            <div class="record-row">
              <div class="record-label">Created At</div>
              <div class="record-value">
                {{ tag?.createdAt | formatTimestamp }}
              </div>
            </div>
            <div class="record-row">
              <div class="record-label">Updated By</div>
              <div class="record-value">
                {{ tag?.UpdatedByUser?.lastName }}, {{
                tag?.UpdatedByUser?.firstName }} ({{
                tag?.UpdatedByUser?.userName }})
              </div>
            </div>
            <div class="record-row">
              <div class="record-label">Updated At</div>
              <div class="record-value">
                {{ tag?.updatedAt | formatTimestamp }}
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="activeSegment === 'content'">
          <ion-list>
            <ng-container *ngFor="let c of content">
              <ion-item
                button
                (click)="editContent(c)"
                *ngIf="!c.hide"
                [ngClass]="[c.type, c.status]"
              >
                <ion-icon
                  name="notifications-outline"
                  *ngIf="c.type === 'BANNER'"
                ></ion-icon>
                <ion-icon
                  name="document-text-outline"
                  *ngIf="c.type === 'HTML'"
                ></ion-icon>
                <ion-icon
                  name="open-outline"
                  *ngIf="c.type === 'LINK'"
                ></ion-icon>
                <ion-icon
                  name="film-outline"
                  *ngIf="c.type === 'VIDEO'"
                ></ion-icon>
                <ion-label>
                  {{ c.name }}
                  <div class="details">
                    <div class="detail-line">
                      <span
                        class="status-indicator"
                        [ngClass]="c.status"
                      ></span>
                      {{ c.status }}
                    </div>
                  </div>
                </ion-label>
                <ion-badge color="danger" *ngIf="!c.isActive"
                  >Inactive</ion-badge
                >
              </ion-item>
            </ng-container>

            <p *ngIf="!content || content.length === 0">
              Content will display here.
            </p>
          </ion-list>
        </ng-container>
        <div class="form-panel-footer desktop-only">
          <!-- <div class="form-panel-footer-spacer"></div> -->
          <div class="form-panel-footer-inner">
            <ion-button
              [disabled]="!form1.valid || !isNameValid()"
              (click)="save()"
              >Save</ion-button
            >
            <ion-button fill="outline" (click)="dismiss()">Discard</ion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer class="mobile-only" *ngIf="formReady && activeSegment === 'form'">
  <ion-button
    [disabled]="!form1.valid || !isNameValid()"
    (click)="save()"
    >Save</ion-button
  >
</ion-footer>
