<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>Share Content Feedback</ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" (click)="dismiss()" size="small"
        ><ion-icon name="close-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Content Rater -->
  <bh-content-rater
    [userActivity]="userActivity"
    (feedbackSubmitted)="dismiss()"
    *ngIf="userActivity"
  ></bh-content-rater>
</ion-content>
