import { Injectable } from '@angular/core';
import { AuthService } from '../_core/auth/auth.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { UserActivityService } from '../user-activity/user-activity.service';
import { ExplorerObject } from 'src/app/models/explorer-object';
import { ContainerService } from '../container/container.service';
import { ContentService } from '../content/content.service';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { ErrorHandlerService } from '../_core/error-handler/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {
  favorites: BehaviorSubject<ExplorerObject[]> = new BehaviorSubject([]);
  isLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);;

  constructor(
    private authService: AuthService,
    private activityService: UserActivityService,
    private containerService: ContainerService,
    private contentService: ContentService,
    private notifications: NotificationsService,
    private errorHandler: ErrorHandlerService
  ) { }

  async loadFavorites(reload = false) {
    const isLoaded = this.isLoaded.getValue();
    if (!isLoaded || reload) {
      try {
        const authUser = this.authService.getAuthUser();
        const favs = await firstValueFrom(this.activityService.getFavoritesByUser(authUser));
        if (favs) {
          const explorerObjects: ExplorerObject[] = [];
          let eo = null;
          for (const f of favs) {
            if (f.containerId) {
              const container = await firstValueFrom(this.containerService.getById(f.containerId));
              eo = this.containerService.setContainerExploreObject(container, 0);
            } else {
              const content = await firstValueFrom(this.contentService.getById(f.contentId));
              eo = this.contentService.setContentExploreObject(content, 0);
            }
            eo.userActivity = f;
            explorerObjects.push(eo);
          };
          const eoFavorites = await this.reorderFavorites(explorerObjects);
          this.favorites.next(eoFavorites);
          this.isLoaded.next(true);
        }

      } catch (err) {
        this.errorHandler.handleError(err, 'loadFavorites');
      }
    }

  }

  async reorderFavorites(explorerObjects: ExplorerObject[]) {
    let eoFavorites: ExplorerObject[] = [];
    try {
      const contents: ExplorerObject[] = explorerObjects.filter(eo => eo.contentId);
      const containers = explorerObjects.filter(eo => eo.containerId);
      // Loop through each content, add missing containers
      for (const c of contents) {
        if (c && c.contentId) {
          const content = await firstValueFrom(this.contentService.getById(c.contentId));
          if (content && content.id) {
            c.content = content;
            const containerPresent = containers.find(con => con.containerId === content.appContainerId);
            // If container isn't listed, load and add
            if (containerPresent === undefined) {
              const res = await firstValueFrom(this.containerService.getById(content.appContainerId));
              if (res && res.id) {
                const container = this.containerService.setContainerExploreObject(res, 0);
                containers.push(container);
              }
            }
          } else {
            console.log('reorderFavorites: Content not found.');
          }
        }
      }

      // Attach content to appContainers
      for (const c of containers) {
        const containerContents = contents.filter(ct => ct.content.appContainerId === c.containerId);
        c.children = containerContents;
      }
      eoFavorites = containers;
    } catch (err) {
      this.errorHandler.handleError(err, 'reorderFavorites');
    }
    return Promise.resolve(eoFavorites);
  }

}
