<div
  ng2FileDrop
  [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
  (fileOver)="fileOverBase($event)"
  [uploader]="uploader"
  [ngStyle]="{ 'background-image': 'url(' + backgroundImageURL + ')' }"
  class="drop-zone"
  *ngIf="uploader?.queue?.length === 0"
>
  <a
    href="javascript:void(0)"
    (click)="openFileBrowser($event)"
    [ngClass]="{ 'file-uploaded': backgroundImageURL !== '' }"
    class="drop-link"
  >
    <span class="drag-label">{{ uploadLabel }}</span>

    <ion-button class="upload-button" shape="round" fill="outline" [ngClass]="{ 'uploaded': backgroundImageURL !== '' }">Browse device</ion-button>
  </a>
</div>

<div
  class="file-input-container"
  *ngIf="uploader?.queue?.length === 0"
  style="display: none"
>
  <label>
    <input
      type="file"
      [accept]="acceptedTypes"
      id="fileUpload"
      ng2FileSelect
      [uploader]="uploader"
      single
    />
    Select file
  </label>
</div>

<!-- <h2>Files: {{ uploader?.queue?.length }}</h2> -->

<ion-list *ngIf="uploader?.queue?.length > 0">
  <ion-list-header>
    <ion-label>Ready to upload:</ion-label>
  </ion-list-header>
  <ion-reorder-group disabled="false">
    <ion-item *ngFor="let item of uploader.queue; let i = index">
      <ion-label>
        {{ item?.file?.name }}
      </ion-label>
      <ion-icon name="trash" color="danger" (click)="removeItem(item)"></ion-icon>
    </ion-item>
  </ion-reorder-group>
</ion-list>
