<ion-header class="modal-header">
  <ion-toolbar>
    <ion-title>Add {{modeLabel}}</ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" (click)="dismiss()" size="small"
        ><ion-icon name="close-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div class="search-toolbar">
    <ion-searchbar
      mode="ios"
      [placeholder]="'Search ' +  modeLabel + 's'"
      (ionInput)="search($event)"
      [debounce]="200"
    ></ion-searchbar>
    <div class="add-button-wrapper">
      <ion-button fill="outline" (click)="addTag()">
        New {{modeLabel}}
      </ion-button>
    </div>
  </div>
  <!-- <ion-segment [(ngModel)]="activeSegment" (ionChange)="filterByType($event)" *ngIf="tagType === 'all'">
    <ion-segment-button value="tag">
      <div class="inline-segment-label">
        <ion-icon name="pricetag-outline"></ion-icon>Tag
      </div>
    </ion-segment-button>
    <ion-segment-button value="section">
      <div class="inline-segment-label">
        <ion-icon name="list-outline"></ion-icon>Section
      </div></ion-segment-button
    >
    <ion-segment-button value="all">All</ion-segment-button>
  </ion-segment> -->
</ion-header>

<ion-content class="ion-padding">
  <bh-spinner
    type="skeleton"
    [skeletonItemCount]="3"
    *ngIf="isLoading"
  ></bh-spinner>
  <ion-list *ngIf="!isLoading">
    <ng-container *ngFor="let t of tags">
      <ion-item button (click)="select(t)" *ngIf="!t.hide" [ngClass]="[t.type]">
        <ion-icon name="list-outline" *ngIf="t.type === 'SECTION'"></ion-icon>
        <ion-icon name="pricetag-outline" *ngIf="t.type === 'TAG'"></ion-icon>
        <ion-label>
          <div class="name">
            {{ t.name }}
          </div>
          <div class="details">
            <div class="detail-line">{{ t.description }}</div>
          </div>
        </ion-label>
        <ion-badge color="danger" *ngIf="!t.isActive">Inactive</ion-badge>
        <div
          class="content-edit"
          *ngIf="authUser.role === 'ADMIN' || authUser.role === 'SYS_ADMIN'"
        >
          <ion-button
            shape="round"
            fill="clear"
            (click)="edit(t, $event)"
          >
            <ion-icon name="pencil-outline"></ion-icon>
            Edit
          </ion-button>
        </div>
      </ion-item>
    </ng-container>

    <p *ngIf="!tags || tags.length === 0">
      <span class="text-transform-capitalize">{{ activeSegment }}</span> will
      display here.
    </p>
  </ion-list>
</ion-content>
