import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { User } from 'src/app/models/user';
import { UserActivity } from 'src/app/models/user-activity';
import { Content } from 'src/app/models/content';
import { ContentContainer } from 'src/app/models/container';
import { ExplorerObject } from 'src/app/models/explorer-object';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  favorites: BehaviorSubject<ExplorerObject[]> = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { }

  getAllByUser(user: User, activeOnly?: boolean, skip?: number, take?: number) {
    const qryActiveOnly = activeOnly ? `&activeOnly=${activeOnly}` : '';
    const qrySkip = skip && !Number.isNaN(skip) ? `&skip=${skip}` : '';
    const qryTake = take && !Number.isNaN(take) ? `&take=${take}` : '';
    const url = `${environment.apiUrl}/users/${user.userId}/activity?${qryActiveOnly}${qrySkip}${qryTake}`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }

  getFavoritesByUser(user: User) {
    const url = `${environment.apiUrl}/users/${user.userId}/activity?activeOnly=true&isFavorite=true`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }


  getByUserAndContentId(user: User, content: Content) {
    const url = `${environment.apiUrl}/users/${user.userId}/activity?contentId=${content.id}&activeOnly=true`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }


  getByUserAndContainerId(user: User, container: ContentContainer) {
    const url = `${environment.apiUrl}/users/${user.userId}/activity?containerId=${container.id}&activeOnly=true`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }

  getById(user: User, userActivity: UserActivity) {
    const url = `${environment.apiUrl}/users/${user.userId}/activity/${userActivity.id}`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }

  create(user: User, userActivity: UserActivity) {
    const url = `${environment.apiUrl}/users/${user.userId}/activity`;
    // console.log('user activity service: activity created', userActivity);
    const body = this.prepareBody(userActivity);
    return this.http.post(url, body).pipe(
      map((data: any) => data),
    );
  }

  update(user: User, userActivity: UserActivity) {
    const url = `${environment.apiUrl}/users/${user.userId}/activity/${userActivity.id}`;
    // console.log('user activity service: updated userActivity', userActivity);
    const body = this.prepareBody(userActivity);
    return this.http.patch(url, body).pipe(
      map((data: any) => data),
    );
  }

  prepareBody(userActivity: UserActivity): any {
    const body = Object.assign({}, userActivity);
    delete body.createdAt;
    delete body.createdBy;
    delete body.CreatedByUser;
    delete body.id;
    delete body.updatedAt;
    delete body.updatedBy;
    delete body.UpdatedByUser;
    delete body.Container;
    delete body.Content;
    delete body.userId;
    delete body.userName;
    delete body.isActive;
    return body;
  }

}
