<ion-content [fullscreen]="true">
  <ion-list>
    <ion-item  *ngIf="content?.type !== 'EVENT'">
      <ion-icon name="time-outline" slot="start"></ion-icon>
      <ion-label>Estimated Time</ion-label>
      <ion-label slot="end" *ngIf="content?.durationMin">{{ content?.durationMin }}m</ion-label>
      <ion-label slot="end" *ngIf="!content?.durationMin">-</ion-label>
    </ion-item>
    <ion-item>
      <ion-icon name="eye-outline" slot="start"></ion-icon>
      <ion-label>Views</ion-label>
      <ion-label slot="end">{{ content?.views }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-icon name="thumbs-up-outline" slot="start"></ion-icon>
      <ion-label>Likes</ion-label>
      <ion-label slot="end">{{ content?.likes }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
