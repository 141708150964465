<ion-menu contentId="main-content" (ionWillClose)="dismissMenu()" side="end">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="closeMenu()">
          <ion-icon name="play-skip-forward" slot="icon-only" class="margin-left-0 margin-right-0"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Manage WorkTips</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list>
      <ion-list-header>Manage</ion-list-header>
      <ion-item button (click)="manageContainers()">
        <ion-icon name="compass-outline" slot="start"></ion-icon>
        <ion-label>Content Explorer</ion-label>
      </ion-item>
      <ion-item button (click)="manageTags()">
        <ion-icon name="pricetag-outline" slot="start"></ion-icon>
        <ion-label>Tags</ion-label>
      </ion-item>
      <ion-item button (click)="manageUsers()">
        <ion-icon name="people-outline" slot="start"></ion-icon>
        <ion-label>Users</ion-label>
      </ion-item>
    </ion-list>
    <ion-list>
      <ion-list-header>Editor Settings</ion-list-header>
      <ion-item>
        <ion-toggle
          [(ngModel)]="presentationMode"
          (ngModelChange)="setPresentationMode($event)"
          [ngModelOptions]="{ standalone: true }"
          slot="start"
        ></ion-toggle>
        <ion-label class="clickable-label" (click)="togglePresentationMode($event)">Hide Editor Tools</ion-label>
      </ion-item>
    </ion-list>
    <ion-list>
      <ion-list-header>Help</ion-list-header>
      <ion-item button (click)="openEditorGuide()">
        <ion-icon name="help-circle-outline" slot="start"></ion-icon>
        <ion-label>Editor's Guide</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-menu>
<ion-app id="main-content">
  <ion-router-outlet [swipeGesture]="false"></ion-router-outlet>
</ion-app>
<div class="loader-backdrop" [ngClass]="{ show: isLoading }">
  <div class="spinner-wrapper">
    <div class="spinner-background">
      <ion-spinner class="loading-modal-spinner"></ion-spinner>
      <div class="loading-message" *ngIf="loadingMessage">
        {{ loadingMessage }}
      </div>
    </div>
  </div>
</div>
