import { Pipe, PipeTransform } from '@angular/core';
import { ContentTag } from '../models/tag';
import { HelperUtilitiesService } from '../services/_core/helper-utilities/helper-utilities.service';


@Pipe({
  name: 'formatContentTags'
})
export class FormatContentTagsPipe implements PipeTransform {
  constructor(
    private helpers: HelperUtilitiesService
) { }

  transform(contentTags: ContentTag[], filter: 'none' | 'section' | 'tag' = 'none'): any {
    const tags = (filter !== 'none') ? contentTags.filter(t => t.Tag.type === filter.toUpperCase()) : contentTags;
    let tagNames: string[] = [];
    for (const t of tags) {
      tagNames.push(this.helpers.capitalizeString(t.Tag.name));
    }
    return tagNames.length > 0 ? tagNames.join(', ') : '-';
  }

}
