<ion-header>
  <ion-toolbar>
    <ion-title>{{ content?.name }}</ion-title>
    <ion-buttons slot="end">
      <div class="social-bar">
        <a
          href="javascript:void(0)"
          class="social-bar-item link icon-only"
          (click)="openEllipsisMenu($event)"
        >
          <ion-icon name="ellipsis-vertical"></ion-icon>
        </a>
        <a
          href="javascript:void(0)"
          class="social-bar-item link icon-only"
          (click)="setFavorite()"
          *ngIf="!userActivity?.isFavorite"
        >
          <ion-icon name="star-outline"></ion-icon>
        </a>
        <a
          href="javascript:void(0)"
          class="social-bar-item link icon-only"
          (click)="setFavorite()"
          *ngIf="userActivity?.isFavorite"
        >
          <ion-icon name="star" color="warning"></ion-icon>
        </a>

        <a
          href="javascript:void(0)"
          class="social-bar-item link button"
          (click)="shareFeedback()"
        >
          Share feedback
        </a>
      </div>
      <ion-button shape="round" (click)="dismiss()" size="small"
        ><ion-icon name="close-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <iframe
    [src]="url | safe: 'resource-url'"
    [ngClass]="{'tango': content.type === 'TANGO'}"
  ></iframe>
</ion-content>
