import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { Content } from 'src/app/models/content';
import { ContentType } from 'src/app/models/content-type';
import { ContentStatusType } from 'src/app/models/content-status-type';
import { ContentContainer } from 'src/app/models/container';
import { ExplorerObject } from 'src/app/models/explorer-object';
import { ContentTypeOption } from 'src/app/models/content-type-option';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { }

  getAll(parentId?: string, activeOnly?: boolean, tags?: string[], skip?: number, take?: number) {
    const qryParentId = parentId ? `&parentContainerId=${parentId}` : '';
    const qryActiveOnly = activeOnly ? `&activeOnly=${activeOnly}` : '';
    const qryTags = Array.isArray(tags) && tags.length > 0 ? `&tags=${tags.join(',')}` : '';
    const qrySkip = skip && !Number.isNaN(skip) ? `&skip=${skip}` : '';
    const qryTake = take && !Number.isNaN(take) ? `&take=${take}` : '';
    const url = `${environment.apiUrl}/contents?${qryParentId}${qryActiveOnly}${qryTags}${qrySkip}${qryTake}`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }

  getByType(type?: ContentType, parentId?: string, activeOnly?: boolean, tags?: string[], skip?: number, take?: number) {
    const qryParentId = parentId ? `&parentContainerId=${parentId}` : '';
    const qryActiveOnly = activeOnly ? `&activeOnly=${activeOnly}` : '';
    const qryTags = Array.isArray(tags) && tags.length > 0 ? `&tags=${tags.join(',')}` : '';
    const qrySkip = skip && !Number.isNaN(skip) ? `&skip=${skip}` : '';
    const qryTake = take && !Number.isNaN(take) ? `&take=${take}` : '';
    const url = `${environment.apiUrl}/contents?type=${type}&${qryParentId}${qryActiveOnly}${qryTags}${qrySkip}${qryTake}`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }

  getNewest() {
    const url = `${environment.apiUrl}/contents?take=10&activeOnly=true`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }

  getFeatured() {
    const url = `${environment.apiUrl}/contents?take=10&activeOnly=true&featured=HOME`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }


  getById(contentId: string, incrementViews = false): Observable<Content> {
    const qryIncrement = incrementViews ? '?incrementViews=true' : '';
    const url = `${environment.apiUrl}/contents/${contentId}${qryIncrement}`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }

  getAllByAppId(appContainerId: string, activeOnly = true): Observable<Content> {
    const qryActiveOnly = activeOnly ? `&activeOnly=${activeOnly}` : '';
    const url = `${environment.apiUrl}/contents/?appContainerId=${appContainerId}${qryActiveOnly}`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }

  search(qry: string): Observable<any[]> {
    const url = `${environment.apiUrl}/contents/search?qry=${qry}&activeOnly=true`;
    return this.http.get(url).pipe(
      map((data: any) => data),
    );
  }

  create(content: Content) {
    // console.log('content service: content created', content);
    const url = `${environment.apiUrl}/contents`;
    const body = this.prepareBody(content);
    return this.http.post(url, body).pipe(
      map((data: any) => data),
    );
  }

  update(content: Content) {
    // console.log('content service:  updated content', content);
    const url = `${environment.apiUrl}/contents/${content.id}`;
    const body = this.prepareBody(content);
    return this.http.patch(url, body).pipe(
      map((data: any) => data),
    );
  }

  uploadFile(content: Content, rawFile: any) {
    const body = new FormData();
    body.append('file', rawFile);
    // console.log('**formData **', body.get('file'));
    const url = `${environment.apiUrl}/contents/${content.id}/upload`;
    // console.log('content service: image uploaded', content);
    return this.http.post(url, body).pipe(
      map((data: any) => data),
    );
  }

  prepareBody(content: Content): any {
    const body = Object.assign({}, content);
    // File Content Tags
    if (body && body.ContentTags && body.ContentTags.length > 0) {
      // console.log('prepareBody: preparing Tags:', body.ContentTags);
      const tags: string[] = [];
      const commonTags = body.ContentTags.filter(ct => ct.Tag.type === 'TAG');

      // console.log('prepareBody: commonTags:', commonTags);

      for (const t of commonTags) {
        if (t.isActive && t.Tag) {
          tags.push(t.Tag.name);
        }
      }
      body.tags = tags;

      // console.log('prepareBody: tags:', tags);
    } else {
      body.tags = [];
    }
    // delete body.tags;
    delete body.ContentSection;
    delete body.ClassContainer;
    delete body.SubCategoryContainer;
    delete body.AppContainer;
    delete body.TabContainer;
    delete body.createdAt;
    delete body.createdBy;
    delete body.CreatedByUser;
    delete body.id;
    delete body.ContentTags;
    delete body.updatedAt;
    delete body.updatedBy;
    delete body.UpdatedByUser;
    delete body.hide;
    delete body.show;
    delete body.likes;
    delete body._count;
    delete body.isFavorite;
    return body;
  }

  getContentTypes(): ContentTypeOption[] {
    return [
      {
        icon: 'share-outline',
        label: 'Upload',
        value: 'UPLOAD',
      },
      {
        icon: 'open-outline',
        label: 'Web Link',
        value: 'LINK'
      },
      {
        icon: 'document-text-outline',
        label: 'Web Document',
        value: 'HTML'
      },
      {
        icon: 'film-outline',
        label: 'YouTube Video',
        value: 'VIDEO'
      },
      {
        icon: 'play-outline',
        label: 'Tango Tutorial',
        value: 'TANGO'
      },
    ];
  }

  translateContentTypeCode(type: ContentType) {
    switch (type) {
      case 'BANNER':
        return 'Announcement';

      case 'HTML':
        return 'Web Document';

      case 'LINK':
        return 'Web Link';

      case 'UPLOAD':
        return 'Uploaded File';

      case 'VIDEO':
        return 'YouTube Video';

      case 'TANGO':
        return 'Tutorial';

      case 'UNDEFINED':
        return 'Content';

      case 'GOLIVE':
        return 'Go-Live Page';

      case 'EVENT':
        return 'Event';
    }
  }

  translateContentStatusTypeCode(status: ContentStatusType) {
    switch (status) {
      case 'DRAFT':
        return 'Draft';

      case 'PUBLISHED':
        return 'Published';

      case 'DELETED':
        return 'Deleted';

      case 'ARCHIVED':
        return 'Archived';
    }
  }


  setContentTypeIcon(type: ContentType): string {
    switch (type) {
      case 'BANNER':
        return 'alert-outline';

      case 'GOLIVE':
        return 'rocket-outline';

      case 'HTML':
        return 'document-text-outline';

      case 'LINK':
        return 'open-outline';

      case 'TANGO':
        return 'play-outline';

      case 'VIDEO':
        return 'film-outline';

      default:
        return '';
    }
  }
  getAttachmentIcon(content: Content) {
    console.log('content', content);

    //console.log('string icon', icon);
    const iconType = content.icon !== null ? content.icon.split('.').pop().toLowerCase() : '';

    switch (iconType) {
      case 'pdf':
        return 'document-outline';

      case 'jpg':
        return 'image-outline';

      case 'png':
        return 'image-outline';

      case 'svg':
        return 'image-outline';

      case 'html':
        return 'link-outline';

      default:
        return 'open-outline';
    }
  }

  setContentExploreObject(content: Content, sortIndex, loadContent = false) {
    const explorerObject: ExplorerObject = {
      parentId: content.parentId,
      contentId: content.id,
      content: content,
      objectType: 'content',
      name: content.name,
      description: content.description,
      sort: sortIndex,
      type: content.type,
      isActive: content.isActive,
      children: [],
      isExpanded: false,
      icon: this.setContentTypeIcon(content.type),
      url: content.url,
      startDateTime: content.startDateTime,
      endDateTime: content.endDateTime,
      isFavorite: false
    };

    return explorerObject;
  }




}
