<div class="icon-selector">
  <div class="is-label">{{ label }}</div>
  <div class="is-selected-icon">
    <ion-icon [name]="selectedIcon?.name" *ngIf="selectedIcon"></ion-icon>
    <div class="is-no-icon-text" *ngIf="!selectedIcon">No icon selected.</div>
    <ion-button id="icon-select" fill="outline">
      <span *ngIf="!selectedIcon">Browse Icons</span>
      <span *ngIf="selectedIcon">Change Icon</span>
    </ion-button>
  </div>
  <ion-modal trigger="icon-select" triggerAction="click">
    <ng-template>
      <ion-header class="modal-header">
        <ion-toolbar>
          <ion-title>Select Icon</ion-title>
          <ion-buttons slot="end">
            <ion-button shape="round" (click)="dismiss()"
              ><ion-icon name="close-outline"></ion-icon
            ></ion-button>
          </ion-buttons>
        </ion-toolbar>
        <ion-searchbar
          mode="ios"
          placeholder="Search icons"
          (ionInput)="search($event)"
          [debounce]="200"
        ></ion-searchbar>
      </ion-header>
      <ion-content>
        <div class="is-icon-list">
          <ng-container *ngFor="let ic of icons">
            <a
              href="javascript:void(0)"
              (click)="selectIcon(ic)"
              [ngClass]="{
                selected: selectedIcon && ic.name === selectedIcon.name
              }"
              *ngIf="!ic.hide"
            >
              <ion-icon [name]="ic.name"></ion-icon>
              <div class="is-icon-label">{{ ic.name }}</div>
            </a>
          </ng-container>
        </div>
      </ion-content>
    </ng-template>
  </ion-modal>
</div>
