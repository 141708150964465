import { BhFileUploaderComponent } from './bh-file-uploader.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';

@NgModule({
  declarations: [
    BhFileUploaderComponent
    ],
  imports: [
    FileUploadModule,
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  exports: [
    BhFileUploaderComponent
  ]
})
export class BhFileUploaderComponentModule { }
