import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription, firstValueFrom } from 'rxjs';
import { SelectOption } from 'src/app/models/_core/select-option';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { ErrorHandlerService } from 'src/app/services/_core/error-handler/error-handler.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-manage-user-editor',
  templateUrl: './manage-user-editor.page.html',
  styleUrls: ['./manage-user-editor.page.scss'],
})
export class ManageUserEditorPage implements OnInit {
  @Input() editMode: 'new' | 'edit' = 'new';
  @Input() userId: string;
  user: User;
  initUserName = '';
  modalTitle = '';
  formReady = false;
  // isChecking = false;
  checkStatus: 'not-checked' | 'checking' | 'new' | 'exists' = 'not-checked';
  checkTimer = null;
  authUser: User;

  form1: FormGroup = this.formBuilder.group({
    userName: [null, Validators.required],
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    role: [null, Validators.required],
    isActive: [true],
  });
  subscriptions: Subscription[] = [];
  showErrorMessage = false;
  submitAttempted = false;
  validationMessages = {
    userName: [{ type: 'required', message: 'User Name is required.' }],
    firstName: [{ type: 'required', message: 'First Name is required.' }],
    lastName: [{ type: 'required', message: 'Last Name is required.' }],
    role: [{ type: 'required', message: 'Role is required.' }],
  };

  roleOptions: SelectOption[] = [
    {
      label: 'General User',
      value: 'USER',
    },
    {
      label: 'Content Admin',
      value: 'ADMIN'
    },
    {
      label: 'System Admin',
      value: 'SYS_ADMIN'
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private authService: AuthService,
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private notifications: NotificationsService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.authUser = this.authService.getAuthUser();
    this.setEditMode();
    this.subscribeToControls();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
      s = null;
    });
  }

  setEditMode() {
    if (this.editMode === 'edit' && this.userId) {
      this.modalTitle = 'Edit User';
      this.loadData();
    } else {
      this.modalTitle = 'New User';
      this.user = {};
    }
  }

  isNameValid() {
    return (
      (this.editMode === 'edit' && this.checkStatus !== 'new' && this.initUserName === this.form1.controls.name.value.toLowerCase()) ||
      (this.checkStatus === 'new')
    );
  }

  subscribeToControls() {
    if (this.subscriptions.length === 0) {
      this.subscriptions.push(
        this.form1.controls.userName.valueChanges.subscribe(async val => {
          if (this.initUserName.toLowerCase() !== val.toLowerCase()) {
            this.checkForExistingUser(val);
          } else {
            this.checkStatus = 'not-checked';
            this.formReady = true;
          }
        })
      )
    }
  }

  async loadData() {
    try {
      const res = await firstValueFrom(this.userService.getById(this.userId));
      if (res) {
        this.user = res;
        this.initUserName = !this.initUserName ? this.user.userName : this.initUserName;
        // console.log('Loading user', this.user);
        this.form1.controls.userName.setValue(res.userName);
        this.form1.controls.firstName.setValue(res.firstName);
        this.form1.controls.lastName.setValue(res.lastName);
        const parsedRole = this.authService.setRole(res);
        this.form1.controls.role.setValue(parsedRole);
        this.form1.controls.isActive.setValue(res.isActive);
        this.checkStatus = 'new';
      }
    } catch (err) {
      this.modalCtrl.dismiss();
      this.errorHandler.handleError(err, 'manage-user-editor.loadData');
    }
  }

  async checkForExistingUser(userName: string) {
    if (this.checkTimer) {
      clearTimeout(this.checkTimer);
      this.checkTimer = null;
    }
    if (userName && !this.checkTimer) {
      this.checkTimer = setTimeout(async () => {
        this.formReady = false;
        this.checkStatus = 'checking';
        try {
          const res = await firstValueFrom(this.userService.getByUserName(userName));
          // console.log('Loading tag', res);
          if (res && res.length > 0) {
            this.checkStatus = 'exists';
            this.formReady = false;
          } else {
            this.checkStatus = 'new';
            this.formReady = true;
          }
        } catch (err) {
          // this.modalCtrl.dismiss();
          this.errorHandler.handleError(err, 'manage-user-editor.loadData');
        }
      }, 500);
    } else {
      this.checkStatus = 'not-checked';
    }

  }

  async impersonate() {
    try {
      const res = await firstValueFrom(this.userService.impersonate(this.user.id));
      this.analytics.clickEvent('manage-user-editor: save', '');
      this.authService.handleLoginResponse(res);
      this.notifications.showToast('Impersonating ' + this.user.firstName + ' ' + this.user.lastName + ' ('+ this.user.userName +')');
      // this.modalCtrl.dismiss({ savedTag: this.tag });
      this.modalCtrl.dismiss({ dismiss: true });
    } catch (err) {
      this.errorHandler.handleError(err, 'manage-user-editor.impersonate');
    }
  }

  async save() {
    // console.log('saving: ', this.form1, this.user, this.editMode);
    this.submitAttempted = true;
    if (this.form1.valid) {
      try {
        // Update container properties
        this.user.userName = this.form1.controls.userName.value.toLowerCase();
        this.user.firstName = this.form1.controls.firstName.value;
        this.user.lastName = this.form1.controls.lastName.value;
        this.user.isActive = this.form1.controls.isActive.value;
        this.user.roles = [];
        this.user.roles.push(this.form1.controls.role.value);

        // Save container
        if (this.editMode === 'edit' && this.user.id) {
          const res = await firstValueFrom(this.userService.update(this.user));
        } else {
          const res = await firstValueFrom(this.userService.create(this.user));
          this.user.id = res.id;
        }
        this.analytics.clickEvent('manage-tag-editor: save', '');
        this.notifications.showToast('Saved successfully.');
        // this.modalCtrl.dismiss({ savedTag: this.tag });
        this.modalCtrl.dismiss({ isSaved: true });
      } catch (err) {
        this.errorHandler.handleError(err, 'manage-tag-editor.save');
      }
    }
  }

  dismiss() {
    this.analytics.clickEvent('manage-tag-editor: dismiss', '');
    this.modalCtrl.dismiss();
  }

}
